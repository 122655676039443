import { getNotifications, QueryKeys, useInfiniteQuery } from '@lobox/utils';
import type { UseInfiniteQueryType, INotification } from '@lobox/utils';

const useGetNotifications = ({
  onlyUnseen,
  size = 20,
  fromHeader,
}: {
  onlyUnseen?: boolean;
  size?: number;
  fromHeader?: boolean;
}): UseInfiniteQueryType<INotification> => useInfiniteQuery(
  [
    onlyUnseen
      ? QueryKeys.unSeenNotifications
      : fromHeader
      ? `${QueryKeys.notifications}_header`
      : QueryKeys.notifications,
  ],
  {
    func: getNotifications,
    size,
    extraProps: {
      onlyUnseen,
    },
  }
);

export default useGetNotifications;
