import React from 'react';
import ObjectLink from '@lobox/uikit/Link/ObjectLink';
import {
  APP_ENTITIES,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import Layout, { Avatar, Text, UserTitle } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const PublishedNewPage: React.FC<INotificationProps> = ({
  data,
  onSeen,
  menuActions,
}) => {
  const { t } = useTranslation();
  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <ObjectLink
      username={data.username}
      onSuccess={onSeen}
      objectId={data.pageId}
    >
      <Layout
        hasToggleNotification={hasToggleNotification}
        menuActions={menuActions}
        onClick={onSeen}
        icon={
          <Avatar
            objectId={data.userId}
            isCompany={data?.userType === APP_ENTITIES.page}
            src={data?.croppedImageUrl}
          />
        }
        description={
          <>
            <UserTitle title={data?.userTitle} objectId={data.userId} />
            <Text value={` ${t('published_a_n_p')}`} />
          </>
        }
        date={data?.createdDate}
        seen={data?.seen}
      />
    </ObjectLink>
  );
};

export default PublishedNewPage;
