import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import useTheme from '@lobox/uikit/utils/useTheme';
import { QueryKeys, useTranslation, useUpdateInfinityData } from '@lobox/utils';
import useSeenNotification from '@shared/hooks/api-hook/useSeenNotification';
import classes from './HeaderActions.module.scss';

export type Filter = 'all' | 'unseen';

interface INotificationHeaderActionsProps {
  selectedFilter: Filter;
  onFilterClick: (filter: Filter) => void;
  markAllReadVisibility: boolean;
  className?: string;
}

const NotificationHeaderActions: React.FC<INotificationHeaderActionsProps> = ({
  onFilterClick,
  selectedFilter,
  markAllReadVisibility,
  className,
}) => {
  const { isDark } = useTheme();
  const activeSituation = 'primary-blue';
  const inactiveSituation = isDark ? 'secondary-dark' : 'semi-transparent';

  const { replaceAll: replaceAllNotifications } = useUpdateInfinityData([
    QueryKeys.notifications,
  ]);
  const { replaceAll: replaceUnseenNotifications } = useUpdateInfinityData([
    QueryKeys.unSeenNotifications,
  ]);

  const { mutate } = useSeenNotification({ seenAll: true });
  const { t } = useTranslation();

  const handleMarkAllSeen = () => {
    mutate(null, {
      onSuccess: () => {
        replaceAllNotifications({ seen: true });
        replaceUnseenNotifications({ seen: true });
      },
    });
  };
  const handleClickItem = (label: string) => () => onFilterClick(label);

  const list = [
    { id: 'all', label: t('all') },
    { id: 'unseen', label: t('unread') },
  ];

  return (
    <Flex
      flexDir="row"
      className={cnj(classes.headerActionsContainer, className)}
    >
      {list.map(({ id, label }) => (
        <Button
          key={id}
          schema={id === selectedFilter ? activeSituation : inactiveSituation}
          className={classes.button}
          label={label}
          onClick={handleClickItem(id)}
          labelClassName={classes.labelClassName}
        />
      ))}

      {markAllReadVisibility && (
        <Button
          schema={inactiveSituation}
          leftType="fas"
          leftIcon="check"
          className={cnj(classes.button, classes.markAllRead)}
          label={t('mark_all_a_r')}
          onClick={handleMarkAllSeen}
          labelClassName={classes.labelClassName}
        />
      )}
    </Flex>
  );
};

export default NotificationHeaderActions;
