import React from 'react';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import {
  APP_ENTITIES,
  routeNames,
  useTranslation,
  ToggleNotificationList,
} from '@lobox/utils';
import Layout, { Avatar, Text, UserTitle } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';
import NoEvent from '@shared/uikit/NoEvent';

const PostGotComment: React.FC<INotificationProps> = ({
  data,
  onSeen,
  menuActions,
}) => {
  const { t } = useTranslation();
  const {
    userId,
    userTitle,
    userType,
    croppedImageUrl,
    createdDate,
    seen,
    postType,
    type,
  } = data;

  const hasToggleNotification = ToggleNotificationList.includes(type);

  const navigateSearchPage = useNavigateSearchPage();

  const handleClick = (...args) => {
    onSeen(...args);
    navigateSearchPage({
      pathname: routeNames.searchPosts,
      currentEntityId: data.postId,
    });
  };

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClick}
      icon={
        <Avatar
          objectId={userId}
          isCompany={userType === APP_ENTITIES.page}
          src={croppedImageUrl}
        />
      }
      description={
        <>
          <UserTitle title={userTitle} objectId={userId} />
          <Text value=" " />
          <Text value={t('cmnt_on_your')} />
          <Text value=" " />
          <Text
            value={
              postType === 'HIGHLIGHT'
                ? t('highlight').toLowerCase()
                : t('post_sm')
            }
          />
          <Text value="." />
          <NoEvent>
            <RichTextView
              html={data.commentText}
              typographyProps={{
                size: 13,
                color: 'primaryText',
                height: 21,
              }}
              showMore
              row={2}
              moreButtonVisible={false}
            />
          </NoEvent>
        </>
      }
      date={createdDate}
      seen={seen}
    />
  );
};

export default PostGotComment;
