import React from 'react';
import {
  APP_ENTITIES,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import Layout, { Avatar, Text, UserTitle } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const FollowRequestAccepted: React.FC<INotificationProps> = ({
  data,
  onSeen,
  menuActions,
}) => {
  const { t } = useTranslation();
  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={onSeen}
      objectId={data.userId}
      icon={
        <Avatar
          objectId={data.userId}
          isCompany={data?.userType === APP_ENTITIES.page}
          src={data?.croppedImageUrl}
        />
      }
      description={
        <>
          <UserTitle title={data?.userTitle} objectId={data.userId} />
          <Text value={` ${t('acc_y_f_re')}`} />
        </>
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default FollowRequestAccepted;
