import classes from './Alert.component.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import Icon from '@lobox/uikit/Icon';
import cnj from '@lobox/uikit/utils/cnj';

export interface IAlertProps {
  className?: string;
  variant?: 'success' | 'error';
  text: string;
}

const Alert = ({
  variant = 'success',
  text,
  className,
}: IAlertProps): JSX.Element => {
  return (
    <Flex flexDir="row" className={cnj(classes.alertContainer, className)}>
      <Icon
        name={variant === 'success' ? 'check-circle' : 'times-circle'}
        color={variant === 'success' ? 'success' : 'error'}
        size={13}
      />
      <Typography size={14} height={14} color="primaryText" ml={8}>
        {text}
      </Typography>
    </Flex>
  );
};

export default Alert;
