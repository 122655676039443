import React from 'react';
import { useHistory } from '@lobox/utils';

import { ToggleNotificationList, useTranslation } from '@lobox/utils';

import type { INotificationProps } from '../Notification.layout';
import Layout, { Avatar, Text } from '../Notification.layout';

const JobApplicationCounterChanged: React.FC<INotificationProps> = (props) => {
  const { data, onSeen, onDelete, menuActions } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const handleClicked = () => {
    if (!data.seen) onSeen?.();
  };

  const handleApplyClick = () => {
    history.push('/jobs');
    onDelete?.();
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClicked}
      icon={<Avatar objectId="" isCompany src={data?.croppedImageUrl} />}
      description={
        <>
          <Text isBold value={data?.count} />
          <Text value=" " />
          <Text value={+data?.count > 1 ? t('people_sm') : t('person_sm')} />
          <Text value=" " />
          <Text value={t('applied_for')} />
          <Text value=" " />

          <Text isBold value={data?.jobTitle} />
          <Text value=" " />

          <Text value={t('job')} />
          <Text value="." />
        </>
      }
      primaryAction={{
        closeModal:true,
        label: t('apply'),
        onClick: handleApplyClick,
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default JobApplicationCounterChanged;
