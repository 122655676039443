import classes from './Notification.empty.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import NotFound from '@lobox/uikit/NotFound';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { useTranslation } from '@lobox/utils';
import EmptyStateSvg from '@shared/svg/FeedEmptyState';

const EmptyListNotification = ({ filter }: any): JSX.Element => {
  const { getAppObjectPropValue } = useGetAppObject();
  const { t } = useTranslation();

  const title = getAppObjectPropValue({
    userKey: 'fullName',
    pageKey: 'title',
  });

  return (
    <NotFound
      className={cnj(classes.wrapper)}
      title={title}
      titleProps={{ textAlign: 'center' }}
      message={
        filter === 'unseen'
          ? t('there_r_n_unread_notif')
          : t('there_r_n_notif_t_sho')
      }
      image={<EmptyStateSvg />}
    />
  );
};

export default EmptyListNotification;
