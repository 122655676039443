import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import ListItem from '@lobox/uikit/ListItem';
import AvatarCmp from '@lobox/uikit/Avatar';
import Typography from '@lobox/uikit/Typography';
import Button from '@lobox/uikit/Button';
import useTheme from '@lobox/uikit/utils/useTheme';
import DateView from '@lobox/uikit/DateView';
import HeroIcon from '@lobox/uikit/HeroIcon';
import cnj from '@lobox/uikit/utils/cnj';
import Link from '@lobox/uikit/Link';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import AlertCmp from '@shared/components/molecules/Alert';
import {
  preventClickHandler,
  useGlobalDispatch,
  useGlobalState,
} from '@lobox/utils';
import Menu from './Notification.menu';
import type { IAlertProps } from '@shared/components/molecules/Alert';
import type { INotification } from '@lobox/utils';
import classes from './Notification.layout.module.scss';

export interface INotificationProps {
  data: INotification & { networkModel?: any; pageRole?: any };
  onSeen: () => void;
  isLoadingDelete?: boolean;
  onDelete: (props?: { localDelete?: boolean; onSuccess?: () => void }) => void;
  updateNotification?: (notificationData: any) => void;
  increaseBadgeCount?: () => void;
  decreaseBadgeCount?: () => void;
  menuActions: {
    onToggleSeen: Function;
    onDelete: Function;
    onToggleNotification: Function;
  };
}

interface INotificationLayoutProps {
  objectId?: string;
  to?: string;
  description: React.ReactNode;
  icon: any;
  onClick?: () => void;
  primaryAction?: {
    closeModal?: boolean;
    isLoading?: boolean;
    label: string;
    onClick?: () => void;
  };
  secondaryAction?: {
    isLoading?: boolean;
    label: string;
    onClick?: () => void;
  };
  date: Date;
  seen: boolean;
  menuActions: {
    onToggleSeen: Function;
    onDelete: Function;
    onToggleNotification: Function;
  };
  moreContent?: React.ReactNode;
  hasToggleNotification: boolean;
  onNavigate?: () => void;
}

const NotificationLayout: React.FC<INotificationLayoutProps> = ({
  description,
  icon,
  onClick,
  primaryAction,
  secondaryAction,
  date,
  seen,
  objectId,
  to,
  menuActions = {},
  moreContent,
  hasToggleNotification,
  onNavigate,
}) => {
  const { isDark } = useTheme();
  const { onToggleSeen, onDelete, onToggleNotification } = menuActions;
  const globalDispatch = useGlobalDispatch();
  const isOpenNotificationModal = useGlobalState('isOpenNotificationModal');

  const closeModalHandler = () => {
    if (isOpenNotificationModal) {
      globalDispatch({
        type: 'SET_IS_OPEN_NOTIFICATION_PANEL',
        payload: false,
      });
    }
  };

  const handlePrimaryActionClicked = (e: any) => {
    preventClickHandler(e);
    if (primaryAction?.onClick) {
      primaryAction.onClick();
    }
    if (primaryAction?.closeModal) {
      closeModalHandler();
    }
  };
  const handleSecondaryActionClicked = (e: any) => {
    preventClickHandler(e);
    if (secondaryAction?.onClick) {
      secondaryAction.onClick();
    }
  };
  const onClickHandler = (e: any) => {
    onClick?.(e);
    closeModalHandler();
  };

  const Wrapper = ({ children }: any) =>
    objectId ? (
      <ObjectLink objectId={objectId} onSuccess={onNavigate}>
        {children}
      </ObjectLink>
    ) : to ? (
      <Link to={to || ''}>{children}</Link>
    ) : (
      <>{children}</>
    );

  return (
    <Wrapper>
      <ListItem
        labelTruncated
        secondaryLabelTruncated
        className={cnj(
          classes.notifContainer,
          !seen && classes.unSeenContainer,
          'listItemClassName'
        )}
        leftSvg={icon}
        labelsContainerClassName={classes.contentContainer}
        hover
        hoverBgColor="brand_10"
        onClick={onClickHandler}
        renderContent={
          <Flex>
            <Flex className={classes.messageWrap}>{description}</Flex>
            {!!moreContent && (
              <Flex className={classes.moreContent}>{moreContent}</Flex>
            )}
            {primaryAction && (
              <Flex className={classes.contentActionsContainer}>
                <Button
                  isLoading={primaryAction.isLoading}
                  schema="primary-blue"
                  label={primaryAction.label}
                  onClick={handlePrimaryActionClicked}
                  className={classes.contentActionBtn}
                />
                {secondaryAction && (
                  <Button
                    isLoading={secondaryAction.isLoading}
                    className={classes.contentActionBtn}
                    schema={isDark ? 'ghost-black' : 'primary-light'}
                    label={secondaryAction.label}
                    onClick={handleSecondaryActionClicked}
                  />
                )}
              </Flex>
            )}
            <DateView
              value={date}
              height={17}
              size={12}
              color="border"
              mt={6}
            />
          </Flex>
        }
        action={() => (
          <Flex className={classes.rightActionsContainer}>
            <Menu
              onToggleSeen={onToggleSeen}
              onDelete={onDelete}
              onToggleNotification={onToggleNotification}
              seen={seen}
              hasToggleNotification={hasToggleNotification}
            />
            {!seen && (
              <Icon
                type="fas"
                name="circle"
                size={11}
                color="brand"
                className={classes.unreadIcon}
              />
            )}
          </Flex>
        )}
      />
    </Wrapper>
  );
};

export const Alert = (props: IAlertProps): JSX.Element => (
  <AlertCmp {...props} />
);

export const Avatar = ({
  src,
  isCompany = false,
  objectId,
  onNavigate,
}: {
  objectId: string;
  src?: string;
  isCompany?: boolean;
  onNavigate: () => void;
}): JSX.Element => {
  return (
    <AvatarCmp
      isCompany={isCompany}
      size="sm"
      imgSrc={src}
      className={classes.image}
    />
  );
};
export const IconSvg = ({ iconName }: { iconName: string }): JSX.Element => {
  return (
    <HeroIcon
      iconName={iconName}
      color="brand"
      size={36}
      iconSize={18}
      iconType="fas"
      className={classes.image}
    />
  );
};
export const Text = ({
  isBold,
  value,
}: {
  isBold?: boolean;
  value: string;
}) => {
  return (
    <Typography
      font={isBold ? 'bold' : undefined}
      size={15}
      height={20}
      color="primaryText"
      className={classes.text}
      isWordWrap
    >
      {value}
    </Typography>
  );
};
export const UserTitle = ({
  title,
  objectId,
  onNavigate,
}: {
  title: string;
  objectId: string;
  onNavigate: any;
}): JSX.Element => <Text isBold value={title} />;

export default NotificationLayout;
