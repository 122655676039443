import classes from './index.module.scss';

import React, { forwardRef } from 'react';
import type { ComponentProps, ReactHTML } from 'react';
import Flex from '../Flex';
import cnj from '../utils/cnj';

type Props = ComponentProps<typeof Flex>;

const NoEvent = (props: Props, ref: any) => {
  const { className, ...rest } = props;
  return (
    <Flex ref={ref} {...rest} className={cnj(classes.wrapper, className)} />
  );
};

export default forwardRef<any, Props>(NoEvent);
