import React, { useState } from 'react';
import { ToggleNotificationList, useTranslation } from '@lobox/utils';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import Layout, { Avatar, Text, UserTitle, Alert } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const PageYouMayKnow: React.FC<INotificationProps> = ({
  data,
  onSeen,
  isLoadingDelete,
  onDelete,
  menuActions,

  updateNotification,
  decreaseBadgeCount,
}) => {
  const { t } = useTranslation();

  const [result, setResult] = useState<any>(
    data.isFollowedAlready
      ? {
          text: `${t('you_started_following')}.`,
        }
      : data.isDeclinedAlready
        ? {
            text: `${t('you_declined_following')}.`,
            variant: 'error',
          }
        : null
  );

  const { followHandler, isLoading: isLoadingFollow } = useObjectNetwork();

  const handleFollowClicked = () => {
    onSeen?.();
    const id = data.userId;
    followHandler(
      { id, isPage: true },
      {
        onSuccess: () => {
          setResult({
            text: `${t('you_started_following')}.`,
          });
          updateNotification({ isFollowedAlready: true, seen: true });
          decreaseBadgeCount();
        },
      }
    );
  };

  const handleDelete = () => {
    onDelete({
      localDelete: false,
      onSuccess: () => {
        setResult({
          text: `${t('you_declined_following')}.`,
          variant: 'error',
        });
        updateNotification({ isDeclinedAlready: true, seen: true });
      },
    });
  };
  const hasToggleNotification = ToggleNotificationList.includes(data.type);
  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={onSeen}
      objectId={data.userId}
      icon={
        <Avatar objectId={data.userId} isCompany src={data?.croppedImageUrl} />
      }
      description={
        <>
          <Text isBold value={t('suggestion_cap')} />
          <Text isBold value=": " />
          <Text value={t('you_may_know')} />
          <Text value=" " />
          <UserTitle title={data?.userTitle} objectId={data?.userId} />
          <Text value=". " />
          <Text value={t('do_you_want_to_follow')} />
        </>
      }
      moreContent={
        result ? (
          <Alert text={result.text} variant={result.variant} />
        ) : undefined
      }
      primaryAction={
        !result && {
          isLoading: isLoadingFollow,
          label: t('follow_cap'),
          onClick: handleFollowClicked,
        }
      }
      secondaryAction={
        !result && {
          isLoading: isLoadingDelete,
          label: t('decline'),
          onClick: handleDelete,
        }
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default PageYouMayKnow;
