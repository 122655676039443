import React, { createContext, useContext, useState } from 'react';
import CelebrateMessageModal from '@shared/components/Organism/CelebrateMessage/Celebrate.component';

interface CelebrateModalDataType {
  id: string;
  image: string;
  title: string;
}

export interface EventModalsContextType {
  showCelebrateModal: (data: CelebrateModalDataType) => void;
  hideCelebrateModal: VoidFunction;
}

export const EventModalsContext = createContext<EventModalsContextType>({
  hideCelebrateModal: () => {
    // pass
  },
  showCelebrateModal: () => {
    // pass
  },
});

const EventModalsProvider: React.FC = ({ children }) => {
  const [celebrateModalData, setCelebrateModalData] =
    useState<CelebrateModalDataType>(null);

  const showCelebrateModal = (data: CelebrateModalDataType) => {
    setCelebrateModalData(data);
  };

  const hideCelebrateModal = () => {
    setCelebrateModalData(null);
  };

  return (
    <EventModalsContext.Provider
      value={{
        showCelebrateModal,
        hideCelebrateModal,
      }}
    >
      <>{children}</>

      {!!celebrateModalData && (
        <CelebrateMessageModal
          hideBack
          onBack={hideCelebrateModal}
          onClose={hideCelebrateModal}
          selectedUser={celebrateModalData}
        />
      )}
    </EventModalsContext.Provider>
  );
};

export default EventModalsProvider;

export const useEventModals = (): EventModalsContextType =>
  useContext(EventModalsContext);
