import React from 'react';
import {
  routeNames,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import { useHistory } from '@lobox/utils';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const PasswordChanged: React.FC<INotificationProps> = ({
  data,
  onSeen,
  menuActions,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const handleClicked = () => {
    onSeen?.();
    history.push(routeNames.settingsSecurity);
  };
  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={() => {
        onSeen();
      }}
      to={routeNames.settingsSecurity}
      icon={<Icon iconName="shield-alt" />}
      description={
        <>
          <Text isBold value={t('password_changed')} />
          <Text isBold value=": " />
          &nbsp;
          <Text value={t('your_password_changed')} />
          <Text value=". " />
          <Text value={t('this_not_you')} />
          <Text value=" " />
          <Text value={t('recover_your_password')} />
          <Text value="." />
        </>
      }
      primaryAction={{
        closeModal:true,
        label: t('recover_password'),
        onClick: handleClicked,
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default PasswordChanged;
