import classes from './Celebrate.component.module.scss';

import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import ListItem from '@lobox/uikit/ListItem';
import ModalForm from '@lobox/uikit/Modal/ModalForm';
import useToast from '@lobox/uikit/Toast/useToast';
import { useTranslation, uuId } from '@lobox/utils';
import Celebrate from '@shared/svg/Celebrate';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import { messageTypes } from '@shared/components/Organism/Message/constants';
import sendWSMessage from '@shared/utils/sendWSMessage';
import { useGetMessageObject } from '@shared/components/Organism/Message/hooks';

interface CelebrateMessageModalProps {
  onClose: Function;
  onBack: Function;
  hideBack: boolean;
  selectedUser: any;
}

const CelebrateMessageModal: React.FC<CelebrateMessageModalProps> = ({
  onClose,
  hideBack,
  selectedUser,
}) => {
  const { t } = useTranslation();
  const { authObject } = useGetMessageObject();
  const toast = useToast();

  const sendMessageHandler = ({ text }) => {
    const wsMessage = {
      text,
      type: messageTypes.MESSAGE_TYPE_TEXT,
      id: uuId().create(),
      to: selectedUser?.id,
      uid: authObject.id,
      data: { eventId: 'temp' },
    };
    sendWSMessage(wsMessage);
    toast({
      type: 'success',
      icon: 'check-circle',
      message: t('yr_msg_sent_success'),
    });
    onClose();
  };

  return (
    <ModalForm
      initialValues={{ text: '' }}
      visibleHeaderBorderBottom
      title={t('celebrate')}
      hideBack={hideBack}
      onBack={onClose}
      onClose={onClose}
      image={<Celebrate />}
      visibleRequiredHint={false}
      local
      onSuccess={sendMessageHandler}
      groups={[
        {
          name: 'test',
          cp: () => (
            <ListItem
              className={classes.listItem}
              leftSvg={
                <Avatar
                  className={classes.avatar}
                  imgSrc={selectedUser.image}
                  showStatus={false}
                  size="md"
                />
              }
              label={selectedUser.title}
              labelColor="thirdText"
              labelSize={16}
              labelFont="bold"
              labelClassName={classes.label}
              secondaryLabel={selectedUser.subTitle}
              secondaryLabelColor="fifthText"
              secondaryLabelSize={14}
            />
          ),
        },
        {
          name: 'text',
          cp: 'richtext',
          label: t('send_a_birthday_msg'),
          maxLength: DESCRIPTION_MAX_LENGTH,
        },
      ]}
      primaryAction={{ label: t('send') }}
    />
  );
};

export default CelebrateMessageModal;
