import React, { useState } from 'react';
import {
  APP_ENTITIES,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import useAcceptFollow from '@shared/hooks/api-hook/useAcceptFollow';
import useDeclineFollow from '@shared/hooks/api-hook/useDeclineFollow';
import Layout, { Avatar, Text, UserTitle, Alert } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const FollowRequest = ({
  data,
  onSeen,
  menuActions,

  updateNotification,
  decreaseBadgeCount,
}: INotificationProps): JSX.Element => {
  const { t } = useTranslation();
  const [result, setResult] = useState<any>(
    data.isFollowedAlready
      ? {
          text: `${t('you_accepted_follow')}.`,
        }
      : data.isDeclinedAlready
        ? {
            text: `${t('you_declined_follow')}.`,
            variant: 'error',
          }
        : null
  );
  const { acceptCall, isLoading: isLoadingAccept } = useAcceptFollow();
  const { declineCall, isLoading: isLoadingDecline } = useDeclineFollow();
  const handleAccept = () => {
    const id = data?.userId;
    acceptCall(id, {
      onSuccess: () => {
        setResult({ text: `${t('you_accepted_follow')}.` });
        updateNotification({ isFollowedAlready: true, seen: true });
        decreaseBadgeCount();
      },
    });
  };
  const handleDecline = () => {
    const id = data?.userId;

    declineCall(id, {
      onSuccess: () => {
        setResult({
          text: `${t('you_declined_follow')}.`,
          variant: 'error',
        });
        updateNotification({ isDeclinedAlready: true, seen: true });
        decreaseBadgeCount();
      },
    });
  };
  const hasToggleNotification = ToggleNotificationList.includes(data.type);
  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={onSeen}
      objectId={data.userId}
      icon={
        <Avatar
          objectId={data.userId}
          isCompany={data?.userType === APP_ENTITIES.page}
          src={data?.croppedImageUrl}
        />
      }
      description={
        <>
          <UserTitle title={data?.userTitle} objectId={data.userId} />
          <Text value={` ${t('wan_t_f_y')}`} />
        </>
      }
      moreContent={
        result ? (
          <Alert text={result.text} variant={result.variant} />
        ) : undefined
      }
      primaryAction={
        !result && {
          isLoading: isLoadingAccept,
          label: t('accept'),
          onClick: handleAccept,
        }
      }
      secondaryAction={
        !result && {
          isLoading: isLoadingDecline,
          label: t('decline'),
          onClick: handleDecline,
        }
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default FollowRequest;
