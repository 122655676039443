import React from 'react';
import {
  APP_ENTITIES,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';

import { useEventModals } from '@shared/contexts/eventModals.provider';
import Layout, { Avatar, Text, UserTitle } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const NewBirthday: React.FC<INotificationProps> = ({
  data,
  onSeen,
  onDelete,
  menuActions,
}) => {
  const { t } = useTranslation();
  const { showCelebrateModal } = useEventModals();

  const handleClicked = () => {
    onSeen();
    showCelebrateModal({
      id: data?.userId,
      image: data?.croppedImageUrl,
      title: data?.userTitle,
    });
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <>
      <Layout
        hasToggleNotification={hasToggleNotification}
        menuActions={menuActions}
        onClick={onSeen}
        objectId={data.userId}
        icon={
          <Avatar
            objectId={data.userId}
            isCompany={data?.userType === APP_ENTITIES.page}
            src={data?.croppedImageUrl}
          />
        }
        description={
          <>
            <UserTitle title={data?.userTitle} objectId={data.userId} />
            <Text value={` ${t('has_bir_tod_send_cel_msg')}`} />
          </>
        }
        primaryAction={{
          closeModal:true,
          label: t('celebrate'),
          onClick: handleClicked,
        }}
        secondaryAction={{
          label: t('decline'),
          onClick: onDelete,
        }}
        date={data?.createdDate}
        seen={data?.seen}
      />
    </>
  );
};

export default NewBirthday;
