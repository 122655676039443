import React from 'react';
import {
  APP_ENTITIES,
  routeNames,
  useTranslation,
  ToggleNotificationList,
} from '@lobox/utils';
import Layout, { Avatar, Text, UserTitle } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';

const PostGotReaction: React.FC<INotificationProps> = ({
  data,
  onSeen,
  menuActions,
}) => {
  const { t } = useTranslation();
  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  const navigateSearchPage = useNavigateSearchPage();

  const handleClick = (...args) => {
    onSeen(...args);
    navigateSearchPage({
      pathname: routeNames.searchPosts,
      currentEntityId: data.postId,
    });
  };

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClick}
      icon={
        <Avatar
          objectId={data.userId}
          isCompany={data?.userType === APP_ENTITIES.page}
          src={data?.croppedImageUrl}
        />
      }
      description={
        <>
          <UserTitle
            title={data?.userTitle}
            objectId={data.userId}
          />
          <Text
            value={` ${t('react_to_yr')} ${
              data.postType === 'HIGHLIGHT'
                ? t('highlight').toLowerCase()
                : 'post'
            }.`}
          />
        </>
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default PostGotReaction;
