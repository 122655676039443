import React from 'react';
import ErrorBoundary from '@lobox/uikit/ErrorBoundary/ErrorBoundary';
import Flex from '@lobox/uikit/Flex';
import useToast from '@lobox/uikit/Toast/useToast';
import { useQueryClient } from '@tanstack/react-query';
import {
  useUpdateInfinityData,
  QueryKeys,
  useReactMutation,
  setNotificationSetting,
} from '@lobox/utils';
import useSeenNotification from '@shared/hooks/api-hook/useSeenNotification';
import useDeleteNotification from '@shared/hooks/api-hook/useDeleteNotification';
import cmp from './Notifications.All';

import type { INotification } from '@lobox/utils';

interface INotificationProps {
  data: INotification;
  className?: string;
}

const Notification = ({ data, className }: INotificationProps): JSX.Element => {
  const queryClient = useQueryClient();
  // @ts-ignore
  const Component = cmp?.[data?.type] as React.ElementType;

  const { mutate } = useSeenNotification();

  const { remove: removeFromUnSeen, replace: replaceFromUnSeen } =
    useUpdateInfinityData<any>([QueryKeys.unSeenNotifications]);
  const { remove: removeFromAll, replace: replaceFromUnAll } =
    useUpdateInfinityData<any>([QueryKeys.notifications]);
  const { remove: removeFromHeader, replace: replaceFromHeader } =
    useUpdateInfinityData<any>([`${QueryKeys.notifications}_header`]);

  const { mutate: updateNotificationSettings } = useReactMutation({
    apiFunc: setNotificationSetting,
  });
  const toast = useToast();
  const updateSeenCount = ({ inc }: { inc: boolean }) => {
    const notifCounts: any = queryClient.getQueryData([
      QueryKeys.unSeenNotificationCounts,
    ]);
    const count = Number(notifCounts?.unSeenCount);
    const newNotifCount = {
      ...(notifCounts || {}),
      unSeenCount: count > 0 ? (inc ? count + 1 : count - 1) : 0,
    };
    queryClient.setQueryData(
      [QueryKeys.unSeenNotificationCounts],
      newNotifCount
    );
  };
  const updateSeenListItem = (seen: boolean) => {
    replaceFromUnSeen({ ...data, seen });
    replaceFromUnAll({ ...data, seen });
    replaceFromHeader({ ...data, seen });
  };
  const updateNotification = (notificationData: any) => {
    replaceFromUnSeen({ ...data, ...notificationData });
    replaceFromUnAll({ ...data, ...notificationData });
    replaceFromHeader({ ...data, ...notificationData });
  };
  const increaseBadgeCount = () => {
    updateSeenCount({ inc: true });
  };
  const decreaseBadgeCount = () => {
    updateSeenCount({ inc: false });
  };
  const handleSeen = () => {
    if (!data.seen) {
      updateSeenCount({ inc: false });
      updateSeenListItem(true);
    }
    mutate({
      notificationId: data.id,
      isSeen: true,
    });
  };

  const { mutate: deleteNotification, isLoading: isLoadingDeleteNotification } =
    useDeleteNotification();

  const handleDeleteNotification = (
    {
      localDelete,
      onSuccess,
    }: {
      localDelete?: boolean;
      onSuccess?: () => void;
    } = { localDelete: true }
  ) => {
    if (!data.seen) {
      updateSeenCount({ inc: false });
      updateSeenListItem(true);
    }
    deleteNotification(
      {
        notificationId: data.id,
      },
      {
        onSuccess: () => {
          onSuccess?.();
          if (localDelete) {
            removeFromAll(data.id);
            removeFromUnSeen(data.id);
            removeFromHeader(data.id);
          }
        },
      }
    );
  };
  const handleMenuToggleSeen = () => {
    const val = !!data.seen;
    updateSeenCount({ inc: val });
    updateSeenListItem(!val);
    mutate({
      notificationId: data.id,
      isSeen: !val,
    });
  };
  const handleNotificationVisibility = () => {
    const newModify = { notificationType: data.type, push: false };
    updateNotificationSettings(
      {
        ...newModify,
      },
      {
        onSuccess: () => {
          toast({
            type: 'success',
            icon: 'check-circle',
            title: 'Turn off notification',
            message:
              'You will no longer receive notification like these. You can re-enable them from the settings page.',
          });
        },
      }
    );
  };

  if (!Component) {
    console.log(`${data.type} not supported yet`);
    return <></>;
  }

  return (
    <ErrorBoundary>
      <Flex className={className}>
        <Component
          data={data}
          onSeen={handleSeen}
          isLoadingDelete={isLoadingDeleteNotification}
          onDelete={handleDeleteNotification}
          updateNotification={updateNotification}
          increaseBadgeCount={increaseBadgeCount}
          decreaseBadgeCount={decreaseBadgeCount}
          menuActions={{
            onDelete: () =>
              handleDeleteNotification({
                localDelete: true,
              }),
            onToggleSeen: handleMenuToggleSeen,
            onToggleNotification: handleNotificationVisibility,
          }}
        />
      </Flex>
    </ErrorBoundary>
  );
};

export default Notification;
