import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';
const CelebrateIcon = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      width="290"
      height="190"
      viewBox="0 0 290 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.5902 190C58.5902 190 25.0903 173.611 25.8697 144.612C26.6491 115.614 57.9129 111.077 79.3038 128.957C100.781 146.836 111.777 138.089 122.863 122.967C133.948 107.845 151.011 73.6285 182.102 86.4376C205.311 95.9555 220.813 121.485 225.836 144.523C229.301 160.357 224.277 177.08 212.24 187.487C211.807 187.843 211.599 189.149 210.756 190H58.5902Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M126.154 30.1744C125.719 30.2574 125.339 30.1486 124.966 29.9176C124.587 29.6575 124.204 29.3681 123.978 28.9581C123.757 28.5772 123.618 28.1505 123.653 27.6907C123.696 27.1086 123.972 26.8143 124.517 26.68C125.062 26.5458 125.54 26.6963 125.917 27.1079C126.367 27.596 126.678 28.1409 126.913 28.7608C126.974 28.9303 127.006 29.1054 127.01 29.286C127.011 29.6181 126.881 29.8847 126.543 30.0094C126.467 30.0844 126.293 30.1176 126.154 30.1744Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M144.891 75.4705C144.456 75.5535 144.075 75.4448 143.703 75.2138C143.324 74.9536 142.941 74.6643 142.715 74.2542C142.494 73.8733 142.355 73.4467 142.39 72.9868C142.433 72.4047 142.708 72.1104 143.254 71.9762C143.799 71.8419 144.277 71.9924 144.654 72.404C145.104 72.8921 145.415 73.437 145.65 74.0569C145.711 74.2265 145.743 74.4015 145.747 74.5822C145.748 74.9142 145.617 75.1809 145.28 75.3056C145.204 75.3805 145.036 75.4429 144.891 75.4705Z"
        fill="#507BDD"
      />
      <path
        d="M32.3185 96.453C31.8836 96.5359 31.5033 96.4272 31.1304 96.1962C30.7522 95.9361 30.3685 95.6467 30.1426 95.2366C29.9221 94.8558 29.7831 94.4291 29.8181 93.9692C29.8605 93.3871 30.1362 93.0929 30.6817 92.9586C31.2271 92.8244 31.7051 92.9749 32.0814 93.3865C32.5319 93.8745 32.8427 94.4195 33.0775 95.0393C33.1388 95.2089 33.1712 95.384 33.1746 95.5646C33.176 95.8967 33.0452 96.1633 32.7081 96.288C32.632 96.363 32.458 96.3961 32.3185 96.453Z"
        fill="#FF8C00"
      />
      <path
        d="M63.3384 98.2548C62.9563 98.2975 62.6031 98.3346 62.2519 98.2204C61.8718 98.1117 61.5914 97.8934 61.4868 97.4905C61.3985 97.1751 61.3337 96.825 61.3034 96.4986C61.2568 96.0847 61.4691 95.7723 61.8586 95.6074C62.0797 95.5048 62.3062 95.4315 62.509 95.3928C63.0361 95.3224 63.4796 95.4492 63.8032 95.9009C64.0129 96.2234 64.2751 96.5056 64.4794 96.7988C64.774 97.2561 64.712 97.5699 64.2571 97.8681C64.1519 97.9486 64.0124 98.0054 63.9019 98.0567C63.7098 98.1537 63.5359 98.1869 63.3384 98.2548Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M55.7496 71.3214C55.3674 71.3641 55.0143 71.4013 54.6631 71.287C54.2829 71.1784 54.0025 70.96 53.898 70.5571C53.8097 70.2418 53.7449 69.8917 53.7145 69.5653C53.668 69.1513 53.8802 68.839 54.2698 68.6741C54.4909 68.5715 54.7173 68.4981 54.9202 68.4594C55.4473 68.3891 55.8908 68.5159 56.2144 68.9676C56.424 69.29 56.6863 69.5722 56.8905 69.8655C57.1851 70.3227 57.1232 70.6366 56.6682 70.9348C56.5631 71.0152 56.4236 71.072 56.3131 71.1233C56.121 71.2204 55.9471 71.2535 55.7496 71.3214Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M110.123 42.747C110.175 42.3749 110.26 42.0263 110.467 41.6848C110.674 41.3433 110.966 41.1367 111.359 41.1523C111.694 41.179 112.052 41.171 112.364 41.2324C112.767 41.3064 113.001 41.5942 113.076 42.0026C113.119 42.236 113.134 42.4749 113.084 42.6957C113.002 43.2248 112.743 43.6065 112.208 43.7991C111.842 43.9293 111.511 44.0831 111.139 44.1842C110.628 44.3421 110.33 44.1876 110.175 43.6735C110.153 43.5568 110.126 43.411 110.099 43.2651C110.107 43.1429 110.132 42.9568 110.123 42.747Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M176.621 32.9469C176.244 33.0188 175.938 32.9865 175.629 32.7737C175.457 32.6555 175.367 32.4914 175.363 32.3109C175.371 31.7052 175.437 31.0884 175.751 30.515C176.018 30.011 176.444 29.7183 177.011 29.7008C177.578 29.6832 177.927 29.9489 178.001 30.5088C178.076 30.9173 178.065 31.3424 177.995 31.7785C177.934 32.0924 177.774 32.3645 177.506 32.5365C177.237 32.7085 176.969 32.8805 176.621 32.9469Z"
        fill="#FF8C00"
      />
      <path
        d="M166.101 60.9542C165.724 61.0261 165.418 60.9938 165.108 60.781C164.937 60.6628 164.846 60.4988 164.843 60.3182C164.851 59.7125 164.917 59.0958 165.231 58.5223C165.498 58.0183 165.924 57.7256 166.491 57.7081C167.058 57.6906 167.407 57.9562 167.48 58.5161C167.556 58.9246 167.545 59.3497 167.475 59.7858C167.413 60.0997 167.253 60.3718 166.985 60.5438C166.717 60.7158 166.42 60.8934 166.101 60.9542Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M67.409 56.9823C67.5587 57.4672 67.2993 57.849 66.8733 58.1417C66.2552 58.5314 65.6013 58.5656 64.9697 58.233C64.8032 58.1439 64.6838 57.9854 64.6225 57.8159C64.435 57.1268 64.6169 56.4879 65.0577 55.9508C65.4277 55.5177 65.9151 55.3946 66.4692 55.4701C66.7172 55.5134 66.8999 55.69 67.03 55.9068C67.171 56.182 67.3281 56.5447 67.409 56.9823Z"
        fill="#507BDD"
      />
      <path
        d="M173.174 22.2882C173.26 22.755 173.182 22.9814 172.809 23.2339C172.249 23.6126 171.645 23.7579 170.995 23.4892C170.673 23.3694 170.583 23.2054 170.616 22.897C170.638 22.5303 170.66 22.1635 170.711 21.7913C170.706 21.7621 170.7 21.7329 170.724 21.6982C170.792 20.93 171.202 20.5498 171.959 20.5866C172.26 20.5897 172.49 20.6968 172.683 20.9318C172.962 21.3016 173.072 21.7338 173.176 22.1367C173.158 22.2006 173.163 22.2298 173.174 22.2882Z"
        fill="#507BDD"
      />
      <path
        d="M69.3096 82.8492C69.1417 82.4282 69.1585 82.0323 69.3546 81.6325C69.5453 81.2035 69.765 80.7689 70.1276 80.4581C70.4612 80.1528 70.8454 79.9587 71.2855 79.905C71.8759 79.8528 72.2089 80.0309 72.4328 80.5922C72.6513 81.1244 72.5927 81.6189 72.2571 82.0755C71.8743 82.6016 71.383 83.0275 70.8175 83.377C70.6544 83.4686 70.4859 83.5309 70.312 83.5641C69.9696 83.6596 69.7053 83.5288 69.5354 83.2592C69.4505 83.1243 69.3945 82.984 69.3096 82.8492Z"
        fill="#FF8C00"
      />
      <path
        d="M21.9477 77.2776C21.5292 77.4481 21.2812 77.4048 20.9502 77.0753C20.471 76.5929 20.2472 76.0316 20.3603 75.3457C20.4223 75.0318 20.5456 74.8875 20.8698 74.8559C21.2229 74.8187 21.605 74.776 21.9582 74.7389C21.9872 74.7333 22.0161 74.7278 22.0451 74.7223C22.8094 74.6369 23.2327 74.9789 23.373 75.7374C23.4269 76.0291 23.3542 76.2846 23.1547 76.5038C22.8609 76.8618 22.4424 77.0323 22.0583 77.2264C22.0347 77.261 21.9767 77.2721 21.9477 77.2776Z"
        fill="#507BDD"
      />
      <path
        d="M33.0085 52.1796C34.7037 52.9127 38.9889 54.0273 39.192 50.9097C39.299 49.38 37.5949 48.4372 36.3176 49.3147C34.3753 50.6511 35.9085 53.5885 37.3464 54.5518C39.6434 56.1059 42.6698 54.9552 43.7717 52.4812C43.9859 52.0178 43.2804 51.609 43.0716 52.1016C42.458 53.4864 41.1733 54.486 39.6419 54.4762C37.9892 54.4594 36.2062 52.928 36.2464 51.1998C36.3014 49.2273 38.8442 49.678 38.3755 51.0352C38.0993 51.8123 36.9493 52.243 36.198 52.2353C35.2096 52.2427 34.2906 51.8143 33.4113 51.4387C32.9282 51.2591 32.52 51.9709 33.0085 52.1796Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M157.26 3.32634C157.995 5.02933 160.346 8.81132 162.596 6.69009C163.72 5.62947 163.138 3.77655 161.619 3.52221C159.299 3.149 158.418 6.33875 158.8 8.07892C159.423 10.8004 162.391 12.0775 164.864 11.0014C165.336 10.7907 165.074 10.0247 164.603 10.2355C163.212 10.8331 161.589 10.6593 160.486 9.57018C159.316 8.43376 159.063 6.09478 160.277 4.86611C161.667 3.45278 163.2 5.57756 161.937 6.21143C161.197 6.59427 160.081 6.08184 159.538 5.58107C158.813 4.90364 158.426 3.94999 158.05 3.05472C157.814 2.58619 157.047 2.8231 157.26 3.32634Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M78.5828 43.0065C79.9752 41.7744 81.6163 40.1022 80.9325 38.0278C80.4599 36.6078 78.5502 35.8545 77.453 37.0605C76.2797 38.3415 77.663 39.9804 78.9465 40.4302C80.7368 41.025 82.7008 40.2879 83.8364 38.8028C84.1484 38.3808 83.4952 37.9315 83.1832 38.3535C82.483 39.2724 81.4228 39.8673 80.2493 39.8495C79.3185 39.8458 76.6579 38.6016 78.3381 37.4655C79.8552 36.4211 80.5404 38.8275 80.1913 39.8605C79.8477 40.9228 78.9123 41.7053 78.1163 42.431C77.6614 42.7292 78.1966 43.352 78.5828 43.0065Z"
        fill="#FF8C00"
      />
      <path
        d="M285.785 150.987C284.773 146.902 279.462 146.902 276.427 149.37C275.5 150.136 274.657 151.157 273.983 152.093C273.814 151.753 273.561 151.498 273.14 151.498L269.599 151.157C269.262 151.157 268.924 151.327 268.672 151.583C268.166 150.476 267.491 149.37 266.733 148.434C264.203 145.37 258.977 144.434 257.206 148.264C255.436 152.093 258.724 154.136 259.82 155.072C260.663 155.753 265.637 155.412 268.25 155.242C268.334 155.668 268.672 156.093 269.177 156.093L272.718 156.433C273.224 156.519 273.645 156.178 273.73 155.753C276.174 156.433 281.064 157.71 281.991 157.199C283.34 156.433 286.881 155.072 285.785 150.987ZM261.927 149.966C263.36 149.03 266.48 151.583 268.419 153.2L268.25 154.731C267.323 154.476 265.974 154.136 264.878 153.795C262.686 153.2 259.904 151.327 261.927 149.966ZM277.355 154.987C276.174 155.072 274.826 155.157 273.814 155.242L273.983 153.71C276.174 152.434 279.715 150.561 280.98 151.668C282.75 153.455 279.631 154.817 277.355 154.987Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M248.017 161.625L250.379 190H261.338H286.629L288.736 162.816L261.674 158.306L248.017 161.625Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M248.017 161.625L261.674 158.306L261.338 190H249.957L248.017 161.625Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M248.017 161.625L261.674 158.306L288.736 162.816L288.314 168.943L261.759 166.39L248.523 168.177L248.017 161.625Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M289.494 166.135L261.506 163.412L247.512 166.22L246.669 158.646L261.674 152.689L290 158.476L289.494 166.135Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M261.506 163.412L247.512 166.22L246.669 158.646L261.674 152.689L261.59 163.497L261.506 163.412Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M278.029 155.923L276.933 190H272.297L273.645 154.987L278.029 155.923Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M217.5 170.475L218.343 190H231.579H256.701L257.122 171.411L231.579 167.411L217.5 170.475Z"
        fill="#5ED6CC"
      />
      <path
        d="M217.5 170.475L218.765 190H231.579H256.701L257.122 171.411L231.579 167.411L217.5 170.475Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M217.5 170.475L231.579 167.411V190H218.765L217.5 170.475Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M217.5 170.475L231.579 167.411L257.122 171.411L256.954 176.772L231.747 174.815L217.922 176.687L217.5 170.475Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M257.881 174.304L231.41 172.092L216.826 174.815L216.067 167.582L231.579 162.39L258.05 167.752L257.881 174.304Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M231.41 172.092L216.826 174.815L216.067 167.582L231.579 162.39V172.092H231.41Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M247.259 165.454V190H243.212L243.212 164.603L247.259 165.454Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M211.852 149.115C209.913 145.03 204.433 146.221 201.735 149.54C200.977 150.561 200.302 151.838 199.796 152.944C199.544 152.604 199.206 152.434 198.785 152.519L194.991 152.944C194.57 153.029 194.232 153.285 194.148 153.625C193.389 152.604 192.462 151.497 191.45 150.732C188.078 148.093 182.43 148.264 181.334 152.689C180.238 157.199 184.116 158.561 185.465 159.327C186.477 159.922 191.703 158.391 194.401 157.54C194.57 158.05 194.991 158.305 195.581 158.305L199.459 157.795C199.965 157.71 200.387 157.284 200.387 156.774C203.169 156.944 208.564 157.199 209.407 156.348C210.419 155.497 213.791 153.199 211.852 149.115ZM186.645 153.54C187.91 152.263 191.872 154.136 194.148 155.497L194.317 157.114C193.221 157.114 191.788 157.029 190.523 156.944C188.078 156.859 184.706 155.412 186.645 153.54ZM203.927 155.327C202.747 155.752 201.314 156.093 200.302 156.433L200.134 154.816C202.073 152.944 205.445 150.136 206.962 151.072C209.154 152.434 206.288 154.561 203.927 155.327Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M225.256 165.369L223.823 189.999H198.954H174.59L174.253 167.667L207.215 161.284L225.256 165.369Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M225.424 164.81L207.384 160.725V189.999H223.823L225.424 164.81Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M225.256 165.369L207.215 161.284L174.253 167.667L174.422 175.496L207.215 171.922L224.919 174.305L225.256 165.369Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M173.157 171.837L207.553 168.007L226.183 171.581L226.942 161.284L207.131 154.136L173.073 162.305L173.157 171.837Z"
        fill="url(#paint18_linear)"
      />
      <path
        d="M207.553 168.007L226.183 171.581L226.942 161.284L207.131 154.136L207.3 168.092L207.553 168.007Z"
        fill="url(#paint19_linear)"
      />
      <path
        d="M187.151 159.339L187.567 190H193.053V157.662L187.151 159.339Z"
        fill="url(#paint20_linear)"
      />
      <path
        d="M209.66 155.327C209.66 155.327 211.936 156.433 213.538 160.859C215.14 165.284 214.718 174.305 214.718 174.305L217.416 165.795L221.294 174.049C221.294 174.049 220.198 160.859 215.14 154.817C215.14 154.817 211.599 152.349 209.66 155.327Z"
        fill="url(#paint21_linear)"
      />
      <path
        d="M186.814 158.986C186.814 158.986 184.369 157.029 180.407 158.476C176.445 159.923 170.291 171.837 170.291 171.837L176.782 167.667C176.782 167.667 176.866 174.645 177.372 174.475C177.878 174.305 181.503 164.092 183.442 161.965C185.465 159.752 186.814 158.986 186.814 158.986Z"
        fill="url(#paint22_linear)"
      />
      <path
        d="M44.0059 150.768L42.9946 190H30.3493H4.63703L1.34891 154.258L28.6629 147.449L44.0059 150.768Z"
        fill="#5ED6CC"
      />
      <path
        d="M43.8373 150.762L42.838 189.915L30.3425 190H4.68495L1.68605 154.244L28.6762 147.449L43.8373 150.762Z"
        fill="url(#paint23_linear)"
      />
      <path
        d="M44.0059 150.768L28.6628 147.449L30.7704 190H43.4158L44.0059 150.768Z"
        fill="url(#paint24_linear)"
      />
      <path
        d="M44.0055 150.751L28.6625 147.438L1.34851 154.234L1.93863 161.37L28.9997 157.292L43.8369 159.076L44.0055 150.751Z"
        fill="url(#paint25_linear)"
      />
      <path
        d="M0.758722 158.057L29.1686 153.724L44.8489 156.528L45.3547 146.843L28.4099 140.641L0 149.307L0.758722 158.057Z"
        fill="url(#paint26_linear)"
      />
      <path
        d="M29.1686 153.724L44.8489 156.528L45.3547 146.843L28.4099 140.641L29 153.724H29.1686Z"
        fill="url(#paint27_linear)"
      />
      <path
        d="M11.8023 145.415L14.5931 190H19.3896L16.2499 144.045L11.8023 145.415Z"
        fill="url(#paint28_linear)"
      />
      <path
        d="M52.4363 152.811C50.8346 149.237 45.945 150.088 43.5846 152.896C42.6573 154.002 41.8985 155.534 41.3927 156.725C41.2241 156.555 41.0555 156.47 40.8026 156.47L38.0206 156.725C37.7677 156.725 37.5991 156.896 37.4305 157.066C36.7561 155.96 35.7445 154.598 34.6485 153.662C31.7822 151.279 26.8927 151.279 25.8811 155.023C24.8694 158.853 28.1572 160.129 29.3375 160.81C30.2648 161.406 35.3229 160.044 37.4305 159.449V159.789C37.4305 160.215 37.852 160.555 38.2735 160.555L41.0555 160.3C41.477 160.3 41.8142 159.874 41.8142 159.449V159.108C44.0904 159.364 49.2328 159.789 50.0759 159.108C51.0032 158.257 54.0381 156.47 52.4363 152.811ZM33.6369 158.938C31.5293 158.683 28.6631 157.406 30.3491 155.874C31.6136 154.768 35.3229 156.896 37.2619 158.087L37.3462 159.278C36.4189 159.193 34.9014 159.023 33.6369 158.938ZM45.2706 157.917C44.0061 158.257 42.573 158.598 41.6456 158.853L41.5613 157.662C43.2474 156.13 46.6195 153.407 48.0526 154.343C49.9916 155.619 47.3782 157.406 45.2706 157.917Z"
        fill="url(#paint29_linear)"
      />
      <path
        d="M64.07 166.001L62.3838 190H50.1599H24.8692L23.3519 167.193L50.4973 162.597L64.07 166.001Z"
        fill="url(#paint30_linear)"
      />
      <path
        d="M64.07 166.001L50.4973 162.597V190H62.3836L64.07 166.001Z"
        fill="url(#paint31_linear)"
      />
      <path
        d="M64.07 166.001L50.4973 162.597L23.3519 167.193L23.7734 173.575L50.3287 170.852L63.5642 172.724L64.07 166.001Z"
        fill="url(#paint32_linear)"
      />
      <path
        d="M22.5932 170.597L50.5816 167.874L64.5758 170.682L65.4188 162.938L50.413 156.896L22.0874 162.768L22.5932 170.597Z"
        fill="url(#paint33_linear)"
      />
      <path
        d="M50.5817 167.874L64.5759 170.682L65.4189 162.938L50.4131 156.896L50.4974 167.874H50.5817Z"
        fill="url(#paint34_linear)"
      />
      <path
        d="M34.0583 160.215L34.9853 190H39.622L38.442 159.278L34.0583 160.215Z"
        fill="url(#paint35_linear)"
      />
      <path
        d="M121.829 94.9058L120.714 72.6408C120.714 72.6408 122.665 71.3311 124.755 72.6408V94.9058H121.829Z"
        fill="url(#paint36_linear)"
      />
      <path
        d="M122.712 58.0936C122.712 58.0936 113.376 73.6698 122.712 73.6698C132.047 73.6698 122.712 58.0936 122.712 58.0936Z"
        fill="url(#paint37_linear)"
      />
      <path
        d="M122.712 62.6307C122.712 62.6307 117.046 72.0326 122.712 72.0326C128.378 72.0326 122.712 62.6307 122.712 62.6307Z"
        fill="#D7E9FF"
      />
      <path
        d="M103.809 120.866L99.3038 98.4139C99.3038 98.4139 122.851 88.4976 148.674 96.309L146.073 118.434C146.119 118.434 127.217 115.206 103.809 120.866Z"
        fill="url(#paint38_linear)"
      />
      <path
        d="M107.431 111.511C113.237 112.493 111.797 98.0398 111.797 98.0398C111.797 98.0398 115.327 111.184 118.717 109.734C122.108 108.284 122.897 95.9349 122.897 95.9349C122.897 95.9349 126.288 110.061 131.629 109.593C136.784 109.126 138.456 97.7124 138.549 96.964C138.549 96.9172 138.549 96.9172 138.549 96.9172C138.549 96.9172 138.549 96.9172 138.549 96.964C138.456 97.7124 136.923 109.453 140.825 109.453C143.054 109.453 145.887 105.664 147.977 102.296L148.674 96.4027C122.851 88.5444 99.3038 98.4608 99.3038 98.4608L100.418 103.933C102.276 107.582 104.738 111.043 107.431 111.511Z"
        fill="url(#paint39_linear)"
      />
      <path
        d="M108.128 114.505C103.809 114.505 99.8612 103.653 99.4432 102.343C99.3039 102.016 99.4897 101.642 99.8148 101.502C100.14 101.361 100.511 101.548 100.651 101.876C102.276 106.46 105.76 113.57 108.314 113.149C110.729 112.774 111.426 104.074 111.147 98.0401C111.147 97.7127 111.379 97.4321 111.704 97.3853C112.029 97.3385 112.355 97.5724 112.401 97.8998C114.073 104.823 116.906 112.166 118.671 112.166H118.717C120.482 111.979 121.922 103.56 122.247 95.8885C122.247 95.5611 122.526 95.3272 122.804 95.2804C123.13 95.2336 123.408 95.4675 123.501 95.7482C125.034 101.735 128.517 111.278 131.397 111.324H131.443C134.276 111.324 136.877 102.437 137.852 96.824C137.899 96.4498 138.27 96.2159 138.595 96.3095C138.967 96.3562 139.199 96.7304 139.106 97.0579C138.131 102.671 137.759 110.857 139.989 111.511C142.218 112.166 145.701 105.477 147.466 100.519C147.605 100.192 147.977 100.005 148.302 100.145C148.627 100.285 148.813 100.66 148.674 100.987C147.884 103.185 143.797 113.991 139.617 112.774C137.574 112.166 137.016 108.845 137.016 105.337C135.716 109.079 133.858 112.681 131.397 112.681H131.35C127.82 112.587 124.802 104.635 123.269 99.7241C122.804 105.15 121.643 113.195 118.81 113.476C116.209 113.757 113.841 107.816 112.401 102.998C112.262 107.769 111.472 113.991 108.453 114.505C108.407 114.458 108.268 114.505 108.128 114.505Z"
        fill="#FAFAFA"
      />
      <path
        d="M101.347 101.782C101.347 102.53 100.744 103.091 100.047 103.091C99.3504 103.091 98.7466 102.483 98.7466 101.782C98.7466 101.033 99.3504 100.472 100.047 100.472C100.744 100.472 101.347 101.08 101.347 101.782Z"
        fill="#FAFAFA"
      />
      <path
        d="M113.051 97.8527C113.051 98.6011 112.447 99.1624 111.751 99.1624C111.008 99.1624 110.45 98.5543 110.45 97.8527C110.45 97.1043 111.054 96.543 111.751 96.543C112.447 96.543 113.051 97.1043 113.051 97.8527Z"
        fill="#FAFAFA"
      />
      <path
        d="M122.99 97.5252C123.708 97.5252 124.291 96.9388 124.291 96.2155C124.291 95.4921 123.708 94.9058 122.99 94.9058C122.272 94.9058 121.69 95.4921 121.69 96.2155C121.69 96.9388 122.272 97.5252 122.99 97.5252Z"
        fill="#FAFAFA"
      />
      <path
        d="M139.896 96.8711C139.896 97.6195 139.292 98.1808 138.595 98.1808C137.852 98.1808 137.295 97.5727 137.295 96.8711C137.295 96.1227 137.899 95.5614 138.595 95.5614C139.338 95.5614 139.896 96.1695 139.896 96.8711Z"
        fill="#FAFAFA"
      />
      <path
        d="M148.163 101.174C148.881 101.174 149.463 100.587 149.463 99.864C149.463 99.1407 148.881 98.5543 148.163 98.5543C147.445 98.5543 146.862 99.1407 146.862 99.864C146.862 100.587 147.445 101.174 148.163 101.174Z"
        fill="#FAFAFA"
      />
      <path
        d="M96.4705 148.323L90.0148 127.087C90.0148 127.087 112.447 110.669 157.962 117.966L154.432 142.149C154.432 142.149 120.853 140.184 96.4705 148.323Z"
        fill="url(#paint40_linear)"
      />
      <path
        d="M98.2354 135.366C106.131 134.384 104.227 124.14 104.227 124.14C104.227 124.14 108.081 134.524 114.723 132.092C121.318 129.66 121.318 119.322 121.318 119.322C121.318 119.322 122.2 130.595 130.374 129.519C138.502 128.444 138.688 118.527 138.688 118.527C138.688 118.527 139.477 129.566 147.001 129.519C151.971 129.473 155.361 125.403 157.08 122.597L157.962 117.966C112.447 110.669 90.0148 127.087 90.0148 127.087L92.4299 133.028C94.009 134.618 95.9132 135.647 98.2354 135.366Z"
        fill="url(#paint41_linear)"
      />
      <path
        d="M99.1643 139.81C94.0091 139.81 90.5258 130.128 90.3865 129.707C90.2471 129.38 90.4329 129.005 90.758 128.865C91.0831 128.725 91.4547 128.912 91.594 129.239C92.6622 132.186 96.1919 139.577 100.093 138.36C104.041 137.097 103.948 127.742 103.576 124.234C103.53 123.907 103.762 123.579 104.087 123.533C104.412 123.486 104.738 123.673 104.83 123.954C105.899 127.275 109.568 135.647 113.701 134.759C117.881 133.87 120.157 123.252 120.714 119.229C120.761 118.902 121.039 118.668 121.364 118.668C121.69 118.668 121.968 118.902 122.015 119.229C122.015 119.37 124.244 132.139 130.049 132.28H130.096C134.833 132.28 137.48 122.316 138.084 118.434C138.131 118.107 138.409 117.873 138.734 117.873C139.059 117.873 139.338 118.107 139.385 118.434C139.385 118.574 141.103 132.092 146.862 133.215C152.528 134.291 156.522 122.504 156.522 122.363C156.615 122.036 156.987 121.849 157.358 121.942C157.683 122.036 157.869 122.41 157.776 122.784C157.591 123.299 153.411 135.788 146.63 134.478C141.893 133.543 139.663 126.292 138.688 121.895C137.573 126.292 134.972 133.543 130.142 133.543C130.096 133.543 130.096 133.543 130.049 133.543C125.219 133.449 122.618 126.76 121.457 122.597C120.528 127.181 118.299 135.086 113.98 136.022C113.701 136.068 113.376 136.115 113.097 136.115C109.475 136.115 106.642 131.391 105.063 127.883C105.063 132.092 104.32 138.314 100.511 139.53C100 139.717 99.5823 139.81 99.1643 139.81Z"
        fill="#FAFAFA"
      />
      <path
        d="M92.7085 129.894C92.7085 130.876 91.9189 131.624 90.9901 131.624C90.0612 131.624 89.2716 130.829 89.2716 129.894C89.2716 128.911 90.0612 128.163 90.9901 128.163C91.9189 128.163 92.7085 128.911 92.7085 129.894Z"
        fill="#FAFAFA"
      />
      <path
        d="M105.991 124.047C105.991 125.029 105.202 125.777 104.273 125.777C103.298 125.777 102.555 124.982 102.555 124.047C102.555 123.111 103.344 122.316 104.273 122.316C105.248 122.269 105.991 123.064 105.991 124.047Z"
        fill="#FAFAFA"
      />
      <path
        d="M122.99 119.603C122.99 120.586 122.2 121.334 121.271 121.334C120.296 121.334 119.553 120.539 119.553 119.603C119.553 118.621 120.342 117.873 121.271 117.873C122.247 117.873 122.99 118.668 122.99 119.603Z"
        fill="#FAFAFA"
      />
      <path
        d="M140.36 119.135C140.36 120.118 139.57 120.866 138.641 120.866C137.666 120.866 136.923 120.071 136.923 119.135C136.923 118.153 137.713 117.405 138.641 117.405C139.617 117.405 140.36 118.2 140.36 119.135Z"
        fill="#FAFAFA"
      />
      <path
        d="M158.984 121.661C158.984 122.643 158.194 123.392 157.265 123.392C156.29 123.392 155.547 122.597 155.547 121.661C155.547 120.679 156.336 119.93 157.265 119.93C158.194 119.93 158.984 120.726 158.984 121.661Z"
        fill="#FAFAFA"
      />
      <path
        d="M95.9132 190L84.9525 151.644C84.9525 151.644 126.287 130.829 166.275 141.587L157.59 190H95.9132Z"
        fill="url(#paint42_linear)"
      />
      <path
        d="M99.2107 169.606C107.756 167.641 105.016 149.633 105.016 149.633C105.016 149.633 108.407 167.688 117.138 165.396C125.869 163.104 124.244 143.786 124.244 143.786C124.244 143.786 127.448 162.028 135.53 162.356C143.611 162.636 145.701 143.786 145.701 143.786C145.701 143.786 143.611 161.981 154.432 163.431C158.101 163.946 161.352 159.362 164.046 153.047L166.275 141.587C126.241 130.829 84.9525 151.644 84.9525 151.644L87.414 159.362C90.8044 165.162 95.1236 170.541 99.2107 169.606Z"
        fill="url(#paint43_linear)"
      />
      <path
        d="M99.35 177.09C98.6998 177.09 98.0496 176.949 97.353 176.622C90.758 173.488 84.5809 155.105 84.3023 154.31C84.2094 153.983 84.3487 153.609 84.7203 153.468C85.0454 153.375 85.4169 153.515 85.5563 153.889C85.6027 154.076 91.8262 172.506 97.9103 175.453C98.7463 175.874 99.5358 175.92 100.279 175.64C105.852 173.535 105.109 156.181 104.366 149.679C104.32 149.352 104.552 149.025 104.877 148.978C105.202 148.931 105.527 149.118 105.62 149.492C105.666 149.679 109.614 167.127 115.698 171.009C116.72 171.664 117.695 171.851 118.717 171.617C125.126 170.12 124.383 151.083 123.547 143.879C123.501 143.505 123.733 143.224 124.105 143.178C124.476 143.131 124.755 143.365 124.848 143.739C125.916 150.475 129.956 167.969 135.994 168.062H136.041C141.8 168.062 144.354 151.691 144.958 144.487C145.004 144.113 145.004 143.832 145.004 143.739C145.051 143.365 145.329 143.131 145.701 143.131C146.072 143.178 146.305 143.458 146.305 143.832C146.305 143.973 146.305 144.113 146.258 144.3C146.026 147.481 145.19 165.77 153.55 168.015C154.85 168.343 156.011 168.156 157.172 167.36C163.396 163.104 165.579 144.441 165.579 144.253C165.625 143.879 165.95 143.645 166.275 143.692C166.647 143.739 166.879 144.066 166.833 144.394C166.74 145.189 164.557 163.852 157.869 168.436C156.429 169.419 154.85 169.746 153.178 169.278C147.466 167.735 145.515 160.157 144.958 153.609C143.611 160.812 140.964 169.278 135.948 169.278C135.901 169.278 135.901 169.278 135.855 169.278C130.653 169.185 127.17 159.783 125.266 152.392C125.405 160.484 124.523 171.523 118.903 172.786C117.556 173.114 116.209 172.833 114.909 171.991C110.822 169.419 107.756 161.654 105.992 155.994C106.27 163.431 105.759 174.798 100.604 176.762C100.279 176.996 99.8145 177.09 99.35 177.09Z"
        fill="#FAFAFA"
      />
      <path
        d="M88.4823 155.48C88.4823 156.602 87.5534 157.538 86.4388 157.538C85.3241 157.538 84.3952 156.602 84.3952 155.48C84.3952 154.357 85.3241 153.421 86.4388 153.421C87.5534 153.421 88.4823 154.357 88.4823 155.48Z"
        fill="#FAFAFA"
      />
      <path
        d="M106.967 149.399C106.967 150.521 106.038 151.457 104.923 151.457C103.809 151.457 102.88 150.521 102.88 149.399C102.88 148.276 103.809 147.341 104.923 147.341C106.085 147.341 106.967 148.229 106.967 149.399Z"
        fill="#FAFAFA"
      />
      <path
        d="M126.334 144.02C126.334 145.143 125.405 146.078 124.29 146.078C123.176 146.078 122.247 145.143 122.247 144.02C122.247 142.897 123.176 141.962 124.29 141.962C125.405 141.962 126.334 142.897 126.334 144.02Z"
        fill="#FAFAFA"
      />
      <path
        d="M147.791 143.739C147.791 144.862 146.862 145.797 145.747 145.797C144.633 145.797 143.704 144.862 143.704 143.739C143.704 142.616 144.633 141.681 145.747 141.681C146.862 141.681 147.791 142.616 147.791 143.739Z"
        fill="#FAFAFA"
      />
      <path
        d="M167.762 145.283C167.762 146.405 166.833 147.341 165.718 147.341C164.604 147.341 163.675 146.405 163.675 145.283C163.675 144.16 164.604 143.225 165.718 143.225C166.833 143.225 167.762 144.113 167.762 145.283Z"
        fill="#FAFAFA"
      />
      <path
        d="M228.955 78.0377C226.288 82.0813 226.1 85.0567 227.002 86.8115C228.504 89.7488 231.283 89.3291 233.086 91.9994C234.776 94.4789 234.325 96.8059 233.987 98.9803"
        stroke="#5384EE"
        strokeWidth="0.5294"
        strokeMiterlimit="10"
      />
      <path
        d="M229.03 81.8906C228.767 81.8906 228.467 81.8906 228.204 81.8906C228.091 81.8524 228.016 81.8143 227.903 81.7761C227.114 81.5854 226.438 81.2039 225.875 80.5936C225.537 80.174 225.537 79.7925 225.913 79.411C226.063 79.2585 226.213 79.1822 226.401 79.0677C226.514 78.9914 226.626 78.9533 226.739 78.877C226.701 78.8007 226.701 78.7626 226.701 78.7626C226.589 78.6863 226.476 78.6481 226.326 78.5718C224.673 77.6945 223.208 76.5882 221.969 75.1386C219.152 71.8961 217.763 68.0433 217.537 63.7709C217.312 59.651 218.138 55.7219 220.016 52.0217C221.856 48.3596 224.448 45.3842 227.978 43.3243C230.758 41.6839 233.762 40.8829 236.992 41.1499C240.41 41.4169 243.377 42.7521 245.856 45.1934C247.884 47.1771 249.273 49.5803 250.175 52.2506C251.339 55.6838 251.564 59.1933 250.964 62.7409C250.288 66.8989 248.673 70.6373 245.968 73.8416C243.978 76.2067 241.649 78.1141 238.757 79.2966C235.79 80.5173 232.748 80.8606 229.631 79.8307C229.556 79.7925 229.481 79.8307 229.368 79.8307C229.481 80.0595 229.593 80.2884 229.668 80.4792C229.969 81.1658 229.819 81.5854 229.105 81.8524C229.143 81.8143 229.105 81.8524 229.03 81.8906Z"
        fill="url(#paint44_linear)"
      />
      <path
        d="M250.213 52.1742C249.311 49.5039 247.922 47.1007 245.893 45.117C243.415 42.7138 240.448 41.3787 236.992 41.1116C236.391 41.0735 235.828 41.0735 235.227 41.0735C233.8 41.9127 232.56 42.9427 231.396 44.1634C228.992 46.7192 227.49 49.7328 226.777 53.166C226.138 56.1033 226.138 59.0406 226.964 61.9398C227.603 64.1904 228.654 66.2503 230.232 68.0051C232.185 70.1413 234.589 71.4001 237.443 71.7816C240.11 72.1249 242.663 71.5909 245.03 70.3702C247.02 69.3402 248.71 67.9669 250.1 66.2885C250.475 65.1441 250.738 63.9234 250.964 62.7027C251.565 59.1169 251.377 55.6074 250.213 52.1742Z"
        fill="url(#paint45_linear)"
      />
      <path
        d="M247.771 59.4602C247.696 58.6591 247.696 57.8199 247.583 57.0188C247.358 55.1877 246.757 53.4711 245.893 51.869C245.705 51.4875 245.405 51.1442 245.067 50.8772C244.654 50.5338 244.241 50.7246 244.241 51.2968C244.241 51.6782 244.241 52.0979 244.316 52.4793C244.729 55.3785 244.616 58.2776 244.353 61.1768C244.278 61.9397 244.203 62.7026 244.203 63.4656C244.203 63.847 244.278 64.2285 244.428 64.5718C244.842 65.5255 245.968 65.6781 246.682 64.9152C246.87 64.7244 246.982 64.4574 247.095 64.2285C247.245 63.8852 247.32 63.5419 247.395 63.1985"
        fill="white"
      />
      <path
        d="M239.246 77.7705C235.753 78.4571 232.297 78.152 229.03 76.7024C225.537 75.1384 222.908 72.6207 221.218 69.1875C219.828 66.3646 219.227 63.351 219.227 60.223C219.227 56.2176 220.279 52.4411 222.194 48.9316C222.457 48.4357 222.758 47.9398 223.058 47.4438C221.856 48.8171 220.88 50.343 220.016 52.0215C218.176 55.7217 217.312 59.6508 217.537 63.7706C217.763 68.0431 219.152 71.8959 221.969 75.1765C223.209 76.5879 224.673 77.7323 226.326 78.6097C226.439 78.686 226.551 78.7242 226.701 78.8004C226.739 78.8004 226.739 78.8386 226.739 78.9149C226.626 78.953 226.514 79.0293 226.401 79.1056C226.251 79.2201 226.063 79.2964 225.913 79.4489C225.537 79.8304 225.537 80.25 225.875 80.6315C226.401 81.28 227.115 81.6233 227.903 81.814C228.016 81.8522 228.091 81.8903 228.204 81.9285C228.467 81.9285 228.767 81.9285 229.03 81.9285C229.105 81.8903 229.143 81.8522 229.218 81.814C229.894 81.547 230.082 81.1274 229.781 80.4408C229.668 80.25 229.593 80.0211 229.481 79.7923C229.593 79.7923 229.668 79.7541 229.744 79.7923C232.861 80.8222 235.903 80.4789 238.87 79.2582C240.447 78.6097 241.875 77.7323 243.152 76.7024C241.875 77.122 240.598 77.5035 239.246 77.7705Z"
        fill="url(#paint46_linear)"
      />
      <path
        d="M238.645 88.1465C237.743 91.2745 235.978 91.6941 234.964 93.6015C233.913 95.5851 234.1 96.9965 234.025 98.5224"
        stroke="#5384EE"
        strokeWidth="0.5294"
        strokeMiterlimit="10"
      />
      <path
        d="M239.959 89.5578C239.659 89.4815 239.359 89.3671 239.096 89.2908C239.021 89.2145 238.908 89.1382 238.833 89.0619C238.044 88.6041 237.443 87.9556 237.068 87.1164C236.842 86.5823 236.992 86.1246 237.481 85.8576C237.668 85.7431 237.894 85.705 238.119 85.6668C238.269 85.6287 238.42 85.6287 238.532 85.5905C238.532 85.5142 238.532 85.4761 238.532 85.4379C238.42 85.3235 238.307 85.2091 238.194 85.0946C236.692 83.6069 235.49 81.9284 234.626 79.983C232.673 75.5579 232.486 70.9803 233.612 66.3264C234.701 61.8251 236.917 57.9342 240.072 54.6154C243.227 51.3348 246.983 49.046 251.452 48.016C254.983 47.2149 258.438 47.3675 261.78 48.7408C265.348 50.1904 268.09 52.6318 269.93 56.0268C271.433 58.8115 272.109 61.8251 272.221 64.9913C272.372 69.0348 271.433 72.8495 269.63 76.4735C267.564 80.6696 264.597 84.1028 260.691 86.6205C257.799 88.4897 254.682 89.7104 251.227 89.9774C247.659 90.2826 244.316 89.5959 241.312 87.4597C241.236 87.4216 241.161 87.4216 241.049 87.3834C241.086 87.6886 241.124 87.9175 241.161 88.1845C241.274 89.0237 240.936 89.4052 240.11 89.4815C240.147 89.4815 240.072 89.5196 239.959 89.5578Z"
        fill="url(#paint47_linear)"
      />
      <path
        d="M272.297 64.992C272.184 61.8258 271.508 58.8122 270.006 56.0275C268.165 52.5943 265.424 50.1911 261.856 48.7415C261.217 48.4745 260.616 48.2838 259.978 48.093C258.213 48.4745 256.523 49.1611 254.908 50.0766C251.527 51.984 248.936 54.7305 247.02 58.1256C245.368 61.0248 244.429 64.1528 244.354 67.5478C244.316 70.18 244.729 72.7358 245.856 75.1009C247.246 78.0382 249.424 80.1744 252.316 81.5477C255.058 82.8447 257.95 83.1117 260.879 82.6158C263.358 82.1962 265.612 81.2807 267.64 79.9837C268.428 78.8774 269.104 77.6949 269.743 76.436C271.508 72.8884 272.409 69.0737 272.297 64.992Z"
        fill="url(#paint48_linear)"
      />
      <path
        d="M267.301 71.9339C267.489 71.0565 267.752 70.1791 267.902 69.2636C268.24 67.2418 268.165 65.22 267.752 63.1983C267.639 62.7405 267.452 62.2827 267.189 61.8631C266.851 61.3672 266.362 61.4435 266.175 62.0157C266.024 62.4353 265.912 62.8931 265.837 63.3127C265.348 66.5552 264.259 69.6069 263.02 72.6205C262.682 73.4216 262.381 74.2227 262.118 75.0238C262.006 75.4052 261.931 75.863 261.968 76.2826C262.118 77.4651 263.245 77.9992 264.259 77.3889C264.522 77.2363 264.747 77.0074 264.935 76.7785C265.198 76.4733 265.386 76.13 265.611 75.7867"
        fill="white"
      />
      <path
        d="M252.241 88.6041C248.26 88.1464 244.692 86.6968 241.687 83.9884C238.495 81.1274 236.504 77.5797 235.791 73.3073C235.227 69.8359 235.603 66.4027 236.617 63.084C237.932 58.8115 240.298 55.1494 243.452 52.0596C243.903 51.6018 244.391 51.1822 244.842 50.7626C243.114 51.8307 241.537 53.1277 240.11 54.6535C236.955 57.9342 234.739 61.8251 233.65 66.3264C232.523 70.9803 232.711 75.5579 234.664 79.983C235.528 81.9285 236.73 83.6451 238.232 85.0946C238.345 85.2091 238.457 85.3235 238.57 85.438C238.57 85.4761 238.57 85.5142 238.57 85.5905C238.42 85.6287 238.27 85.6287 238.157 85.6668C237.932 85.705 237.706 85.7431 237.518 85.8576C236.993 86.1246 236.842 86.5824 237.105 87.1164C237.481 87.9556 238.082 88.6041 238.87 89.0619C238.983 89.1382 239.058 89.2145 239.133 89.2908C239.434 89.3671 239.734 89.4815 239.997 89.5578C240.072 89.5578 240.147 89.5197 240.223 89.5197C241.049 89.4815 241.387 89.0619 241.274 88.2227C241.237 87.9556 241.199 87.7268 241.161 87.4216C241.274 87.4597 241.349 87.4597 241.424 87.4979C244.391 89.6341 247.772 90.3207 251.34 90.0156C253.255 89.863 255.058 89.4052 256.748 88.7186C255.246 88.833 253.781 88.7949 252.241 88.6041Z"
        fill="url(#paint49_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="126.383"
          y1="83.6239"
          x2="126.383"
          y2="190"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset="1" stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="255.323"
          y1="147.512"
          x2="256.585"
          y2="159.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="268.376"
          y1="158.306"
          x2="268.376"
          y2="194.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="232.903"
          y1="189.679"
          x2="264.563"
          y2="188.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="202.953"
          y1="167.405"
          x2="290.008"
          y2="141.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="268.334"
          y1="152.689"
          x2="268.334"
          y2="166.22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="230.061"
          y1="164.263"
          x2="264.629"
          y2="161.339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="272.117"
          y1="160.982"
          x2="290.024"
          y2="170.893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="237.311"
          y1="167.411"
          x2="237.311"
          y2="202.388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="201.919"
          y1="197.329"
          x2="234.552"
          y2="196.327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="173.648"
          y1="175.418"
          x2="256.905"
          y2="148.547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="237.058"
          y1="162.39"
          x2="237.058"
          y2="174.815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="198.899"
          y1="173.018"
          x2="234.566"
          y2="169.622"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="242.317"
          y1="170.118"
          x2="257.923"
          y2="177.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="179.678"
          y1="148.492"
          x2="181.155"
          y2="162.247"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="199.755"
          y1="161.284"
          x2="199.755"
          y2="213.111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="187.248"
          y1="205.614"
          x2="229.076"
          y2="204.504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="117.805"
          y1="173.44"
          x2="227.896"
          y2="143.313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="200.008"
          y1="154.136"
          x2="200.008"
          y2="171.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="185.205"
          y1="169.058"
          x2="230.828"
          y2="165.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="186.777"
          y1="166.019"
          x2="208.176"
          y2="175.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="209.116"
          y1="156.975"
          x2="217.418"
          y2="175.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="169.518"
          y1="160.464"
          x2="173.844"
          y2="177.117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="22.7617"
          y1="147.449"
          x2="22.7617"
          y2="193.227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="11.6819"
          y1="186.684"
          x2="47.257"
          y2="185.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="-45.8623"
          y1="159.355"
          x2="47.993"
          y2="137.443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="22.6773"
          y1="140.641"
          x2="22.6773"
          y2="158.057"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint27_linear"
          x1="9.6562"
          y1="154.23"
          x2="48.7124"
          y2="151.053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint28_linear"
          x1="11.4395"
          y1="151.353"
          x2="34.5771"
          y2="165.789"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear"
          x1="24.4187"
          y1="152.05"
          x2="25.5407"
          y2="163.237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear"
          x1="43.711"
          y1="162.597"
          x2="43.711"
          y2="200.297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint31_linear"
          x1="35.4757"
          y1="194.844"
          x2="66.9427"
          y2="193.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint32_linear"
          x1="-21.7129"
          y1="171.987"
          x2="65.762"
          y2="147.247"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint33_linear"
          x1="43.7531"
          y1="156.896"
          x2="43.7531"
          y2="170.682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint34_linear"
          x1="33.8054"
          y1="168.688"
          x2="68.383"
          y2="165.818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint35_linear"
          x1="33.7943"
          y1="165.416"
          x2="51.8971"
          y2="175.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint36_linear"
          x1="120.717"
          y1="83.4931"
          x2="124.758"
          y2="83.4931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint37_linear"
          x1="122.712"
          y1="58.0936"
          x2="122.712"
          y2="73.6698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint38_linear"
          x1="99.338"
          y1="107.101"
          x2="148.716"
          y2="107.101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint39_linear"
          x1="123.989"
          y1="93.374"
          x2="123.989"
          y2="111.559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint40_linear"
          x1="90.0618"
          y1="132.225"
          x2="158.02"
          y2="132.225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint41_linear"
          x1="123.988"
          y1="116.096"
          x2="123.988"
          y2="135.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint42_linear"
          x1="85.0087"
          y1="164.274"
          x2="166.345"
          y2="164.274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint43_linear"
          x1="125.614"
          y1="138.5"
          x2="125.614"
          y2="169.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint44_linear"
          x1="180.109"
          y1="75.9902"
          x2="258.185"
          y2="71.0592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint45_linear"
          x1="198.674"
          y1="67.4272"
          x2="256.407"
          y2="63.8581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint46_linear"
          x1="189.115"
          y1="76.9403"
          x2="248.434"
          y2="73.5759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint47_linear"
          x1="189.406"
          y1="83.8963"
          x2="280.176"
          y2="77.4956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint48_linear"
          x1="213.413"
          y1="77.8217"
          x2="278.029"
          y2="73.8587"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint49_linear"
          x1="206.628"
          y1="84.3966"
          x2="261.708"
          y2="81.8555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="290"
      height="190"
      viewBox="0 0 290 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.475 190C58.475 190 25.041 173.611 25.8188 144.612C26.5967 115.614 57.799 111.077 79.148 128.957C100.583 146.836 111.558 138.089 122.621 122.967C133.685 107.845 150.714 73.6285 181.744 86.4376C204.908 95.9555 220.379 121.485 225.393 144.523C228.85 160.357 223.837 177.08 211.823 187.487C211.39 187.843 211.183 189.149 210.342 190H58.475Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M125.906 30.1737C125.471 30.2568 125.092 30.1482 124.72 29.9174C124.342 29.6574 123.959 29.3683 123.734 28.9584C123.514 28.5776 123.375 28.1511 123.41 27.6913C123.453 27.1093 123.728 26.815 124.273 26.6806C124.817 26.5462 125.294 26.6965 125.67 27.1079C126.119 27.5957 126.43 28.1404 126.664 28.7601C126.725 28.9296 126.757 29.1046 126.76 29.2852C126.762 29.6172 126.631 29.8838 126.295 30.0087C126.219 30.0836 126.045 30.1169 125.906 30.1737Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M144.606 75.47C144.171 75.5531 143.792 75.4445 143.42 75.2137C143.042 74.9537 142.659 74.6645 142.434 74.2546C142.214 73.8739 142.075 73.4473 142.11 72.9876C142.153 72.4056 142.428 72.1113 142.973 71.9768C143.517 71.8424 143.994 71.9927 144.37 72.4041C144.819 72.892 145.13 73.4367 145.364 74.0564C145.425 74.2259 145.457 74.4009 145.461 74.5815C145.462 74.9135 145.331 75.1801 144.995 75.3049C144.919 75.3799 144.75 75.4423 144.606 75.47Z"
        fill="#507BDD"
      />
      <path
        d="M32.2546 96.4523C31.8205 96.5354 31.4409 96.4268 31.0687 96.196C30.6912 95.936 30.3082 95.6468 30.0828 95.2369C29.8628 94.8562 29.7242 94.4296 29.7593 93.9699C29.8019 93.3879 30.0772 93.0936 30.6218 92.9591C31.1663 92.8247 31.6435 92.975 32.019 93.3864C32.4685 93.8743 32.7787 94.419 33.0128 95.0387C33.074 95.2082 33.1063 95.3832 33.1096 95.5638C33.1109 95.8958 32.9803 96.1624 32.6437 96.2872C32.5676 96.3622 32.394 96.3954 32.2546 96.4523Z"
        fill="#FF8C00"
      />
      <path
        d="M63.2136 98.2542C62.8321 98.297 62.4795 98.3343 62.129 98.2202C61.7496 98.1117 61.4697 97.8935 61.3655 97.4908C61.2774 97.1755 61.2129 96.8255 61.1827 96.4992C61.1364 96.0854 61.3484 95.773 61.7373 95.608C61.958 95.5054 62.1841 95.432 62.3866 95.3932C62.9128 95.3227 63.3555 95.4493 63.6783 95.9008C63.8875 96.2231 64.1492 96.5051 64.353 96.7983C64.6469 97.2553 64.585 97.5691 64.1307 97.8673C64.0258 97.9478 63.8865 98.0047 63.7761 98.056C63.5844 98.1531 63.4108 98.1863 63.2136 98.2542Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M55.6396 71.321C55.2581 71.3638 54.9056 71.4011 54.555 71.287C54.1756 71.1785 53.8957 70.9603 53.7915 70.5575C53.7035 70.2423 53.6389 69.8923 53.6087 69.566C53.5624 69.1521 53.7744 68.8398 54.1633 68.6748C54.384 68.5722 54.6101 68.4987 54.8126 68.46C55.3388 68.3895 55.7815 68.5161 56.1044 68.9676C56.3135 69.2899 56.5752 69.5719 56.779 69.865C57.073 70.3221 57.011 70.6358 56.5568 70.9341C56.4518 71.0146 56.3125 71.0714 56.2022 71.1228C56.0104 71.2198 55.8368 71.2531 55.6396 71.321Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M109.907 42.7475C109.958 42.3753 110.043 42.0269 110.25 41.6854C110.457 41.3439 110.748 41.1372 111.14 41.1527C111.475 41.1793 111.833 41.1712 112.143 41.2324C112.546 41.3063 112.779 41.5939 112.855 42.0022C112.898 42.2355 112.912 42.4743 112.862 42.6951C112.78 43.2241 112.521 43.6058 111.988 43.7985C111.622 43.9288 111.291 44.0828 110.92 44.1839C110.41 44.3419 110.112 44.1876 109.958 43.6737C109.936 43.557 109.909 43.4112 109.882 43.2654C109.89 43.1432 109.915 42.9571 109.907 42.7475Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M176.273 32.9466C175.897 33.0186 175.592 32.9864 175.283 32.7737C175.111 32.6556 175.021 32.4916 175.018 32.3111C175.026 31.7055 175.092 31.0889 175.406 30.5155C175.672 30.0115 176.098 29.7187 176.664 29.701C177.23 29.6833 177.578 29.9488 177.651 30.5085C177.727 30.9169 177.715 31.3419 177.646 31.778C177.584 32.0918 177.424 32.3639 177.156 32.536C176.889 32.708 176.621 32.8801 176.273 32.9466Z"
        fill="#FF8C00"
      />
      <path
        d="M165.774 60.9539C165.398 61.0259 165.092 60.9937 164.783 60.781C164.612 60.6629 164.522 60.499 164.518 60.3184C164.526 59.7129 164.593 59.0962 164.906 58.5228C165.173 58.0188 165.598 57.726 166.164 57.7083C166.73 57.6906 167.079 57.9561 167.152 58.5158C167.227 58.9242 167.216 59.3492 167.146 59.7853C167.084 60.0991 166.925 60.3712 166.657 60.5433C166.389 60.7153 166.092 60.8929 165.774 60.9539Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M67.2769 56.9818C67.4262 57.4666 67.167 57.8483 66.7417 58.1411C66.1246 58.531 65.4718 58.5653 64.8414 58.233C64.6752 58.144 64.5561 57.9857 64.4949 57.8162C64.308 57.1272 64.4898 56.4885 64.93 55.9513C65.2995 55.5182 65.786 55.3949 66.3392 55.4703C66.5868 55.5135 66.7691 55.6899 66.8989 55.9067C67.0395 56.1817 67.1962 56.5443 67.2769 56.9818Z"
        fill="#507BDD"
      />
      <path
        d="M172.834 22.2877C172.92 22.7545 172.842 22.9808 172.469 23.2334C171.91 23.6122 171.307 23.7577 170.659 23.4892C170.337 23.3696 170.247 23.2057 170.28 22.8973C170.302 22.5307 170.324 22.164 170.376 21.7918C170.37 21.7626 170.365 21.7334 170.388 21.6987C170.457 20.9307 170.866 20.5503 171.622 20.5869C171.922 20.5899 172.151 20.697 172.344 20.9318C172.622 21.3014 172.732 21.7335 172.836 22.1363C172.818 22.2002 172.823 22.2294 172.834 22.2877Z"
        fill="#507BDD"
      />
      <path
        d="M69.1727 82.8494C69.0053 82.4285 69.0222 82.0328 69.218 81.633C69.4086 81.204 69.628 80.7695 69.99 80.4586C70.3232 80.1533 70.7067 79.9591 71.1461 79.9052C71.7356 79.8528 72.0679 80.0308 72.2912 80.5919C72.5091 81.1239 72.4505 81.6183 72.1153 82.0749C71.733 82.601 71.2424 83.027 70.6778 83.3766C70.515 83.4682 70.3467 83.5306 70.1731 83.5638C69.8313 83.6594 69.5676 83.5287 69.3981 83.2592C69.3133 83.1245 69.2575 82.9842 69.1727 82.8494Z"
        fill="#FF8C00"
      />
      <path
        d="M21.9042 77.2773C21.4864 77.4478 21.2388 77.4046 20.9086 77.0753C20.4303 76.5933 20.2071 76.0322 20.3202 75.3463C20.3822 75.0326 20.5053 74.8883 20.8289 74.8565C21.1815 74.8193 21.5629 74.7764 21.9155 74.7392C21.9444 74.7336 21.9733 74.7281 22.0023 74.7226C22.7652 74.6369 23.1876 74.9788 23.3274 75.7369C23.3812 76.0285 23.3085 76.284 23.1093 76.5032C22.8159 76.8613 22.3981 77.0318 22.0146 77.226C21.991 77.2607 21.9331 77.2717 21.9042 77.2773Z"
        fill="#507BDD"
      />
      <path
        d="M32.9429 52.1813C34.6348 52.9136 38.9118 54.0263 39.1155 50.9098C39.2228 49.3807 37.5222 48.4388 36.2469 49.3164C34.3078 50.653 35.8372 53.5888 37.2721 54.5513C39.5644 56.104 42.5856 54.9527 43.6863 52.4792C43.9002 52.0159 43.1962 51.6075 42.9876 52.1C42.3747 53.4845 41.092 54.4842 39.5634 54.4749C37.9137 54.4587 36.1345 52.9284 36.1752 51.2008C36.2308 49.229 38.7688 49.6788 38.3004 51.0356C38.0245 51.8125 36.8765 52.2434 36.1266 52.236C35.14 52.2437 34.2228 51.8158 33.3452 51.4406C32.8631 51.2613 32.4554 51.9729 32.9429 52.1813Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M156.951 3.32832C157.684 5.03081 160.03 8.81145 162.277 6.68977C163.4 5.62893 162.819 3.77648 161.303 3.52269C158.986 3.15031 158.106 6.3399 158.486 8.07968C159.108 10.8005 162.071 12.0764 164.54 10.9997C165.011 10.7888 164.75 10.0231 164.279 10.234C162.891 10.832 161.27 10.6587 160.17 9.57016C159.001 8.4343 158.75 6.09575 159.962 4.86684C161.35 3.45324 162.881 5.5772 161.619 6.21141C160.88 6.59444 159.767 6.08246 159.225 5.58195C158.5 4.90486 158.115 3.95148 157.74 3.05647C157.504 2.58809 156.738 2.82522 156.951 3.32832Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M78.4268 43.0062C79.8171 41.7739 81.4559 40.1016 80.774 38.0279C80.3026 36.6084 78.3965 35.856 77.3009 37.0621C76.1292 38.3431 77.5096 39.9811 78.7906 40.4305C80.5776 41.0245 82.5384 40.2869 83.6725 38.8017C83.9841 38.3798 83.3322 37.9308 83.0206 38.3528C82.3213 39.2717 81.2628 39.8668 80.0914 39.8494C79.1621 39.8461 76.5066 38.603 78.1843 37.4667C79.6991 36.4219 80.3822 38.8275 80.0335 39.8605C79.6901 40.9226 78.756 41.7052 77.9613 42.431C77.507 42.7293 78.0411 43.3517 78.4268 43.0062Z"
        fill="#FF8C00"
      />
      <path
        d="M285.223 150.987C284.214 146.902 278.913 146.902 275.884 149.37C274.959 150.136 274.117 151.157 273.444 152.093C273.276 151.753 273.023 151.498 272.603 151.498L269.069 151.157C268.732 151.157 268.396 151.327 268.143 151.583C267.639 150.476 266.966 149.37 266.208 148.434C263.684 145.37 258.468 144.434 256.701 148.264C254.934 152.093 258.215 154.136 259.309 155.072C260.15 155.753 265.115 155.412 267.723 155.242C267.807 155.668 268.143 156.093 268.648 156.093L272.182 156.433C272.687 156.519 273.108 156.178 273.192 155.753C275.632 156.433 280.512 157.71 281.437 157.199C282.783 156.433 286.317 155.072 285.223 150.987ZM261.413 149.966C262.843 149.03 265.956 151.583 267.891 153.2L267.723 154.731C266.797 154.476 265.451 154.136 264.357 153.795C262.17 153.2 259.393 151.327 261.413 149.966ZM276.81 154.987C275.632 155.072 274.285 155.157 273.276 155.242L273.444 153.71C275.632 152.434 279.165 150.561 280.427 151.668C282.194 153.455 279.081 154.817 276.81 154.987Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M247.53 161.625L249.887 190H260.824H286.065L288.168 162.816L261.16 158.306L247.53 161.625Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M247.53 161.625L261.16 158.306L260.824 190H249.466L247.53 161.625Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M247.53 161.625L261.16 158.306L288.168 162.816L287.747 168.943L261.244 166.39L248.035 168.177L247.53 161.625Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M288.925 166.135L260.992 163.412L247.025 166.22L246.184 158.646L261.16 152.689L289.43 158.476L288.925 166.135Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M260.992 163.412L247.025 166.22L246.184 158.646L261.16 152.689L261.076 163.497L260.992 163.412Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M277.483 155.923L276.389 190H271.762L273.107 154.987L277.483 155.923Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M217.073 170.475L217.914 190H231.123H256.196L256.617 171.411L231.123 167.411L217.073 170.475Z"
        fill="#5ED6CC"
      />
      <path
        d="M217.073 170.475L218.335 190H231.123H256.196L256.617 171.411L231.123 167.411L217.073 170.475Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M217.073 170.475L231.123 167.411V190H218.335L217.073 170.475Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M217.073 170.475L231.123 167.411L256.617 171.411L256.449 176.772L231.292 174.815L217.493 176.687L217.073 170.475Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M257.374 174.304L230.955 172.092L216.4 174.815L215.642 167.581L231.123 162.39L257.542 167.752L257.374 174.304Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M230.955 172.092L216.4 174.815L215.642 167.581L231.124 162.39V172.092H230.955Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M246.773 165.454V190H242.735L242.734 164.603L246.773 165.454Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M211.435 149.115C209.5 145.03 204.031 146.221 201.339 149.54C200.582 150.561 199.909 151.838 199.404 152.944C199.151 152.604 198.815 152.434 198.394 152.519L194.608 152.944C194.187 153.029 193.851 153.285 193.767 153.625C193.009 152.604 192.084 151.497 191.074 150.732C187.709 148.093 182.072 148.264 180.978 152.689C179.884 157.199 183.754 158.561 185.1 159.327C186.11 159.922 191.327 158.391 194.019 157.54C194.187 158.05 194.608 158.305 195.197 158.305L199.067 157.795C199.572 157.71 199.993 157.284 199.993 156.774C202.769 156.944 208.154 157.199 208.995 156.348C210.005 155.497 213.37 153.199 211.435 149.115ZM186.278 153.54C187.54 152.263 191.495 154.136 193.767 155.497L193.935 157.114C192.841 157.114 191.411 157.029 190.149 156.944C187.709 156.859 184.343 155.412 186.278 153.54ZM203.526 155.327C202.349 155.752 200.918 156.093 199.909 156.433L199.74 154.816C201.675 152.944 205.041 150.136 206.555 151.072C208.743 152.434 205.882 154.561 203.526 155.327Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M224.813 165.369L223.383 189.999H198.562H174.247L173.911 167.667L206.808 161.284L224.813 165.369Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M224.981 164.81L206.976 160.725V189.999H223.383L224.981 164.81Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M224.813 165.369L206.808 161.284L173.911 167.667L174.079 175.496L206.808 171.922L224.477 174.305L224.813 165.369Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M172.817 171.837L207.145 168.007L225.739 171.581L226.496 161.284L206.724 154.136L172.733 162.305L172.817 171.837Z"
        fill="url(#paint18_linear)"
      />
      <path
        d="M207.145 168.007L225.739 171.581L226.496 161.284L206.724 154.136L206.892 168.092L207.145 168.007Z"
        fill="url(#paint19_linear)"
      />
      <path
        d="M186.783 159.339L187.198 190H192.673V157.662L186.783 159.339Z"
        fill="url(#paint20_linear)"
      />
      <path
        d="M209.248 155.327C209.248 155.327 211.52 156.433 213.118 160.859C214.717 165.284 214.296 174.305 214.296 174.305L216.989 165.795L220.859 174.049C220.859 174.049 219.765 160.859 214.717 154.817C214.717 154.817 211.183 152.349 209.248 155.327Z"
        fill="url(#paint21_linear)"
      />
      <path
        d="M186.447 158.987C186.447 158.987 184.007 157.029 180.053 158.476C176.098 159.923 169.956 171.837 169.956 171.837L176.435 167.667C176.435 167.667 176.519 174.645 177.024 174.475C177.528 174.305 181.146 164.093 183.082 161.965C185.101 159.752 186.447 158.987 186.447 158.987Z"
        fill="url(#paint22_linear)"
      />
      <path
        d="M43.9194 150.768L42.9101 190H30.2896H4.62791L1.34625 154.258L28.6066 147.449L43.9194 150.768Z"
        fill="#5ED6CC"
      />
      <path
        d="M43.7511 150.762L42.7538 189.915L30.2829 190H4.67575L1.68274 154.244L28.6198 147.449L43.7511 150.762Z"
        fill="url(#paint23_linear)"
      />
      <path
        d="M43.9194 150.768L28.6065 147.449L30.7099 190H43.3304L43.9194 150.768Z"
        fill="url(#paint24_linear)"
      />
      <path
        d="M43.9191 150.751L28.6062 147.438L1.34589 154.234L1.93484 161.37L28.9427 157.292L43.7508 159.076L43.9191 150.751Z"
        fill="url(#paint25_linear)"
      />
      <path
        d="M0.757231 158.057L29.1113 153.724L44.7607 156.528L45.2656 146.843L28.3541 140.641L0 149.307L0.757231 158.057Z"
        fill="url(#paint26_linear)"
      />
      <path
        d="M29.1113 153.724L44.7607 156.528L45.2655 146.843L28.3541 140.641L28.943 153.724H29.1113Z"
        fill="url(#paint27_linear)"
      />
      <path
        d="M11.7791 145.415L14.5644 190H19.3514L16.218 144.045L11.7791 145.415Z"
        fill="url(#paint28_linear)"
      />
      <path
        d="M52.3333 152.811C50.7347 149.237 45.8548 150.088 43.4989 152.896C42.5734 154.002 41.8162 155.534 41.3114 156.725C41.1431 156.555 40.9748 156.47 40.7224 156.47L37.9459 156.725C37.6935 156.725 37.5252 156.896 37.3569 157.066C36.6838 155.96 35.6742 154.598 34.5804 153.662C31.7198 151.279 26.8399 151.279 25.8302 155.023C24.8206 158.853 28.1019 160.129 29.2798 160.81C30.2053 161.406 35.2535 160.044 37.3569 159.449V159.789C37.3569 160.215 37.7776 160.555 38.1983 160.555L40.9748 160.3C41.3955 160.3 41.732 159.874 41.732 159.449V159.108C44.0037 159.364 49.1361 159.789 49.9775 159.108C50.903 158.257 53.9319 156.47 52.3333 152.811ZM33.5708 158.938C31.4674 158.683 28.6067 157.406 30.2895 155.874C31.5515 154.768 35.2535 156.896 37.1887 158.087L37.2728 159.278C36.3473 159.193 34.8328 159.023 33.5708 158.938ZM45.1817 157.917C43.9196 158.257 42.4893 158.598 41.5638 158.853L41.4796 157.662C43.1624 156.13 46.5278 153.407 47.9582 154.343C49.8933 155.619 47.2851 157.406 45.1817 157.917Z"
        fill="url(#paint29_linear)"
      />
      <path
        d="M63.9441 166.001L62.2612 190H50.0613H24.8203L23.306 167.193L50.3981 162.597L63.9441 166.001Z"
        fill="url(#paint30_linear)"
      />
      <path
        d="M63.9441 166.001L50.3981 162.597V190H62.261L63.9441 166.001Z"
        fill="url(#paint31_linear)"
      />
      <path
        d="M63.9441 166.001L50.3981 162.597L23.306 167.193L23.7267 173.575L50.2298 170.852L63.4393 172.724L63.9441 166.001Z"
        fill="url(#paint32_linear)"
      />
      <path
        d="M22.5488 170.597L50.4822 167.874L64.4489 170.682L65.2903 162.938L50.3139 156.896L22.044 162.768L22.5488 170.597Z"
        fill="url(#paint33_linear)"
      />
      <path
        d="M50.4823 167.874L64.449 170.682L65.2904 162.938L50.314 156.896L50.3982 167.874H50.4823Z"
        fill="url(#paint34_linear)"
      />
      <path
        d="M33.9914 160.215L34.9166 190H39.5441L38.3665 159.278L33.9914 160.215Z"
        fill="url(#paint35_linear)"
      />
      <path
        d="M121.59 94.9058L120.477 72.6408C120.477 72.6408 122.424 71.3311 124.51 72.6408V94.9058H121.59Z"
        fill="url(#paint36_linear)"
      />
      <path
        d="M122.47 58.0936C122.47 58.0936 113.154 73.6698 122.47 73.6698C131.787 73.6698 122.47 58.0936 122.47 58.0936Z"
        fill="url(#paint37_linear)"
      />
      <path
        d="M122.471 62.6307C122.471 62.6307 116.816 72.0326 122.471 72.0326C128.126 72.0326 122.471 62.6307 122.471 62.6307Z"
        fill="#D7E9FF"
      />
      <path
        d="M103.605 120.866L99.1086 98.4139C99.1086 98.4139 122.609 88.4976 148.381 96.309L145.786 118.434C145.832 118.434 126.966 115.206 103.605 120.866Z"
        fill="url(#paint38_linear)"
      />
      <path
        d="M107.22 111.511C113.014 112.493 111.577 98.0398 111.577 98.0398C111.577 98.0398 115.1 111.184 118.484 109.734C121.868 108.284 122.656 95.9349 122.656 95.9349C122.656 95.9349 126.039 110.061 131.37 109.593C136.515 109.126 138.184 97.7124 138.276 96.964C138.276 96.9172 138.276 96.9172 138.276 96.9172C138.276 96.9172 138.276 96.9172 138.276 96.964C138.184 97.7124 136.654 109.453 140.548 109.453C142.773 109.453 145.6 105.664 147.686 102.296L148.381 96.4027C122.609 88.5444 99.1086 98.4608 99.1086 98.4608L100.221 103.933C102.075 107.582 104.532 111.043 107.22 111.511Z"
        fill="url(#paint39_linear)"
      />
      <path
        d="M107.916 114.505C103.605 114.505 99.6649 103.653 99.2478 102.343C99.1087 102.016 99.2941 101.642 99.6186 101.502C99.9431 101.361 100.314 101.548 100.453 101.876C102.075 106.46 105.552 113.57 108.101 113.149C110.511 112.774 111.207 104.074 110.929 98.0401C110.929 97.7127 111.16 97.4321 111.485 97.3853C111.809 97.3385 112.134 97.5724 112.18 97.8998C113.849 104.823 116.676 112.166 118.438 112.166H118.484C120.245 111.979 121.682 103.56 122.007 95.8885C122.007 95.5611 122.285 95.3272 122.563 95.2804C122.888 95.2336 123.166 95.4675 123.258 95.7482C124.788 101.735 128.264 111.278 131.138 111.324H131.185C134.012 111.324 136.608 102.437 137.581 96.824C137.628 96.4498 137.998 96.2159 138.323 96.3095C138.694 96.3562 138.925 96.7304 138.833 97.0579C137.859 102.671 137.489 110.857 139.713 111.511C141.938 112.166 145.415 105.477 147.176 100.519C147.315 100.192 147.686 100.005 148.011 100.145C148.335 100.285 148.52 100.66 148.381 100.987C147.593 103.185 143.514 113.991 139.343 112.774C137.303 112.166 136.747 108.845 136.747 105.337C135.449 109.079 133.595 112.681 131.138 112.681H131.092C127.569 112.587 124.556 104.635 123.027 99.7241C122.563 105.15 121.404 113.195 118.577 113.476C115.981 113.757 113.617 107.816 112.18 102.998C112.041 107.769 111.253 113.991 108.24 114.505C108.194 114.458 108.055 114.505 107.916 114.505Z"
        fill="#FAFAFA"
      />
      <path
        d="M101.148 101.782C101.148 102.53 100.546 103.091 99.8504 103.091C99.1551 103.091 98.5525 102.483 98.5525 101.782C98.5525 101.033 99.1551 100.472 99.8504 100.472C100.546 100.472 101.148 101.08 101.148 101.782Z"
        fill="#FAFAFA"
      />
      <path
        d="M112.829 97.8526C112.829 98.601 112.226 99.1623 111.531 99.1623C110.79 99.1623 110.233 98.5542 110.233 97.8526C110.233 97.1042 110.836 96.5428 111.531 96.5428C112.226 96.5428 112.829 97.1042 112.829 97.8526Z"
        fill="#FAFAFA"
      />
      <path
        d="M122.748 97.5252C123.465 97.5252 124.046 96.9388 124.046 96.2155C124.046 95.4921 123.465 94.9058 122.748 94.9058C122.032 94.9058 121.451 95.4921 121.451 96.2155C121.451 96.9388 122.032 97.5252 122.748 97.5252Z"
        fill="#FAFAFA"
      />
      <path
        d="M139.621 96.8711C139.621 97.6195 139.018 98.1808 138.323 98.1808C137.581 98.1808 137.025 97.5727 137.025 96.8711C137.025 96.1227 137.628 95.5614 138.323 95.5614C139.065 95.5614 139.621 96.1695 139.621 96.8711Z"
        fill="#FAFAFA"
      />
      <path
        d="M147.872 101.174C148.589 101.174 149.17 100.587 149.17 99.864C149.17 99.1407 148.589 98.5543 147.872 98.5543C147.155 98.5543 146.574 99.1407 146.574 99.864C146.574 100.587 147.155 101.174 147.872 101.174Z"
        fill="#FAFAFA"
      />
      <path
        d="M96.2809 148.323L89.838 127.087C89.838 127.087 112.226 110.669 157.652 117.966L154.129 142.149C154.129 142.149 120.616 140.184 96.2809 148.323Z"
        fill="url(#paint40_linear)"
      />
      <path
        d="M98.0423 135.366C105.922 134.384 104.022 124.14 104.022 124.14C104.022 124.14 107.869 134.524 114.497 132.092C121.08 129.66 121.08 119.322 121.08 119.322C121.08 119.322 121.96 130.595 130.118 129.519C138.23 128.443 138.415 118.527 138.415 118.527C138.415 118.527 139.203 129.566 146.712 129.519C151.672 129.473 155.056 125.403 156.771 122.597L157.652 117.966C112.226 110.669 89.838 127.087 89.838 127.087L92.2483 133.027C93.8243 134.618 95.7247 135.647 98.0423 135.366Z"
        fill="url(#paint41_linear)"
      />
      <path
        d="M98.9694 139.81C93.8243 139.81 90.3479 130.128 90.2088 129.707C90.0698 129.379 90.2552 129.005 90.5796 128.865C90.9041 128.725 91.2749 128.912 91.414 129.239C92.4801 132.186 96.0029 139.576 99.8965 138.36C103.836 137.097 103.744 127.742 103.373 124.234C103.327 123.907 103.558 123.579 103.883 123.532C104.207 123.486 104.532 123.673 104.624 123.953C105.691 127.275 109.352 135.647 113.478 134.759C117.649 133.87 119.921 123.252 120.477 119.229C120.523 118.902 120.801 118.668 121.126 118.668C121.45 118.668 121.728 118.902 121.775 119.229C121.775 119.369 124 132.139 129.794 132.279H129.84C134.568 132.279 137.21 122.316 137.813 118.434C137.859 118.107 138.137 117.873 138.462 117.873C138.786 117.873 139.064 118.107 139.111 118.434C139.111 118.574 140.826 132.092 146.573 133.215C152.228 134.291 156.215 122.503 156.215 122.363C156.307 122.036 156.678 121.849 157.049 121.942C157.374 122.036 157.559 122.41 157.466 122.784C157.281 123.299 153.109 135.788 146.342 134.478C141.614 133.542 139.389 126.292 138.415 121.895C137.303 126.292 134.707 133.542 129.887 133.542C129.84 133.542 129.84 133.542 129.794 133.542C124.973 133.449 122.377 126.76 121.219 122.597C120.292 127.181 118.067 135.086 113.756 136.021C113.478 136.068 113.153 136.115 112.875 136.115C109.26 136.115 106.432 131.391 104.856 127.883C104.856 132.092 104.115 138.313 100.314 139.53C99.8038 139.717 99.3866 139.81 98.9694 139.81Z"
        fill="#FAFAFA"
      />
      <path
        d="M92.5263 129.893C92.5263 130.876 91.7383 131.624 90.8112 131.624C89.8842 131.624 89.0962 130.829 89.0962 129.893C89.0962 128.911 89.8842 128.163 90.8112 128.163C91.7383 128.163 92.5263 128.911 92.5263 129.893Z"
        fill="#FAFAFA"
      />
      <path
        d="M105.783 124.047C105.783 125.029 104.995 125.777 104.068 125.777C103.095 125.777 102.353 124.982 102.353 124.047C102.353 123.111 103.141 122.316 104.068 122.316C105.042 122.269 105.783 123.064 105.783 124.047Z"
        fill="#FAFAFA"
      />
      <path
        d="M122.748 119.603C122.748 120.586 121.96 121.334 121.033 121.334C120.06 121.334 119.318 120.539 119.318 119.603C119.318 118.621 120.106 117.873 121.033 117.873C122.006 117.873 122.748 118.668 122.748 119.603Z"
        fill="#FAFAFA"
      />
      <path
        d="M140.084 119.135C140.084 120.117 139.296 120.866 138.369 120.866C137.396 120.866 136.654 120.071 136.654 119.135C136.654 118.153 137.442 117.404 138.369 117.404C139.342 117.404 140.084 118.2 140.084 119.135Z"
        fill="#FAFAFA"
      />
      <path
        d="M158.671 121.661C158.671 122.643 157.883 123.392 156.956 123.392C155.983 123.392 155.241 122.596 155.241 121.661C155.241 120.679 156.029 119.93 156.956 119.93C157.883 119.93 158.671 120.725 158.671 121.661Z"
        fill="#FAFAFA"
      />
      <path
        d="M95.7246 190L84.7855 151.644C84.7855 151.644 126.039 130.829 165.949 141.587L157.281 190H95.7246Z"
        fill="url(#paint42_linear)"
      />
      <path
        d="M99.0157 169.606C107.545 167.641 104.81 149.633 104.81 149.633C104.81 149.633 108.193 167.688 116.908 165.396C125.622 163.104 124 143.786 124 143.786C124 143.786 127.198 162.028 135.263 162.356C143.329 162.636 145.414 143.786 145.414 143.786C145.414 143.786 143.329 161.981 154.129 163.431C157.791 163.946 161.035 159.362 163.724 153.047L165.949 141.587C125.993 130.829 84.7855 151.644 84.7855 151.644L87.2421 159.362C90.6259 165.162 94.9366 170.541 99.0157 169.606Z"
        fill="url(#paint43_linear)"
      />
      <path
        d="M99.1548 177.09C98.5058 177.09 97.8569 176.949 97.1616 176.622C90.5796 173.488 84.4147 155.105 84.1366 154.31C84.0439 153.983 84.1829 153.609 84.5537 153.468C84.8782 153.375 85.249 153.515 85.3881 153.889C85.4344 154.076 91.6457 172.506 97.7178 175.453C98.5522 175.874 99.3402 175.92 100.082 175.64C105.644 173.535 104.902 156.181 104.161 149.679C104.114 149.352 104.346 149.025 104.671 148.978C104.995 148.931 105.32 149.118 105.412 149.492C105.459 149.679 109.399 167.127 115.471 171.009C116.491 171.664 117.464 171.851 118.484 171.617C124.88 170.12 124.139 151.083 123.304 143.879C123.258 143.505 123.49 143.224 123.861 143.178C124.231 143.131 124.51 143.365 124.602 143.739C125.668 150.475 129.701 167.969 135.727 168.062H135.773C141.521 168.062 144.07 151.691 144.673 144.487C144.719 144.113 144.719 143.832 144.719 143.739C144.766 143.365 145.044 143.131 145.415 143.131C145.785 143.178 146.017 143.458 146.017 143.832C146.017 143.973 146.017 144.113 145.971 144.3C145.739 147.481 144.905 165.77 153.248 168.015C154.546 168.343 155.705 168.156 156.864 167.36C163.075 163.104 165.253 144.441 165.253 144.253C165.3 143.879 165.624 143.645 165.949 143.692C166.319 143.739 166.551 144.066 166.505 144.394C166.412 145.189 164.234 163.852 157.559 168.436C156.122 169.419 154.546 169.746 152.877 169.278C147.176 167.735 145.229 160.157 144.673 153.609C143.329 160.812 140.687 169.278 135.681 169.278C135.634 169.278 135.634 169.278 135.588 169.278C130.396 169.185 126.92 159.783 125.019 152.392C125.158 160.485 124.278 171.523 118.669 172.786C117.325 173.114 115.981 172.833 114.683 171.991C110.604 169.419 107.545 161.654 105.783 155.994C106.061 163.431 105.551 174.798 100.406 176.762C100.082 176.996 99.6183 177.09 99.1548 177.09Z"
        fill="#FAFAFA"
      />
      <path
        d="M88.3084 155.48C88.3084 156.602 87.3813 157.538 86.2689 157.538C85.1564 157.538 84.2294 156.602 84.2294 155.48C84.2294 154.357 85.1564 153.421 86.2689 153.421C87.3813 153.421 88.3084 154.357 88.3084 155.48Z"
        fill="#FAFAFA"
      />
      <path
        d="M106.757 149.399C106.757 150.521 105.83 151.457 104.717 151.457C103.605 151.457 102.678 150.521 102.678 149.399C102.678 148.276 103.605 147.341 104.717 147.341C105.876 147.341 106.757 148.229 106.757 149.399Z"
        fill="#FAFAFA"
      />
      <path
        d="M126.086 144.02C126.086 145.143 125.159 146.078 124.046 146.078C122.934 146.078 122.007 145.143 122.007 144.02C122.007 142.897 122.934 141.962 124.046 141.962C125.159 141.962 126.086 142.897 126.086 144.02Z"
        fill="#FAFAFA"
      />
      <path
        d="M147.5 143.739C147.5 144.862 146.573 145.797 145.461 145.797C144.348 145.797 143.421 144.862 143.421 143.739C143.421 142.616 144.348 141.681 145.461 141.681C146.573 141.681 147.5 142.616 147.5 143.739Z"
        fill="#FAFAFA"
      />
      <path
        d="M167.432 145.283C167.432 146.405 166.505 147.341 165.393 147.341C164.28 147.341 163.353 146.405 163.353 145.283C163.353 144.16 164.28 143.224 165.393 143.224C166.505 143.224 167.432 144.113 167.432 145.283Z"
        fill="#FAFAFA"
      />
      <path
        d="M228.505 78.0377C225.843 82.0813 225.656 85.0567 226.556 86.8115C228.055 89.7488 230.829 89.3291 232.628 91.9994C234.315 94.479 233.865 96.8059 233.528 98.9803"
        stroke="#5384EE"
        strokeWidth="0.5294"
        strokeMiterlimit="10"
      />
      <path
        d="M228.58 81.8906C228.317 81.8906 228.018 81.8906 227.755 81.8906C227.643 81.8524 227.568 81.8143 227.455 81.7761C226.668 81.5854 225.993 81.2039 225.431 80.5936C225.094 80.174 225.094 79.7925 225.469 79.411C225.619 79.2585 225.769 79.1822 225.956 79.0677C226.068 78.9914 226.181 78.9533 226.293 78.877C226.256 78.8007 226.256 78.7626 226.256 78.7626C226.143 78.6863 226.031 78.6481 225.881 78.5718C224.232 77.6945 222.77 76.5882 221.533 75.1386C218.722 71.8961 217.335 68.0433 217.11 63.7709C216.885 59.651 217.709 55.7219 219.584 52.0217C221.42 48.3596 224.007 45.3842 227.53 43.3243C230.304 41.6839 233.303 40.8829 236.526 41.1499C239.937 41.4169 242.899 42.7521 245.373 45.1934C247.397 47.1771 248.784 49.5803 249.683 52.2506C250.845 55.6838 251.07 59.1933 250.47 62.7409C249.796 66.8989 248.184 70.6373 245.485 73.8416C243.498 76.2067 241.174 78.1141 238.288 79.2966C235.327 80.5173 232.291 80.8606 229.18 79.8307C229.105 79.7925 229.03 79.8307 228.917 79.8307C229.03 80.0595 229.142 80.2884 229.217 80.4792C229.517 81.1658 229.367 81.5854 228.655 81.8524C228.692 81.8143 228.655 81.8524 228.58 81.8906Z"
        fill="url(#paint44_linear)"
      />
      <path
        d="M249.721 52.1742C248.821 49.5039 247.434 47.1007 245.41 45.117C242.936 42.7138 239.975 41.3787 236.526 41.1116C235.927 41.0735 235.364 41.0735 234.765 41.0735C233.34 41.9127 232.103 42.9427 230.941 44.1634C228.542 46.7192 227.043 49.7328 226.331 53.166C225.694 56.1033 225.694 59.0406 226.518 61.9398C227.156 64.1904 228.205 66.2503 229.779 68.0051C231.729 70.1413 234.128 71.4001 236.976 71.7816C239.638 72.1249 242.187 71.5909 244.548 70.3702C246.535 69.3402 248.221 67.9669 249.608 66.2885C249.983 65.1441 250.246 63.9234 250.47 62.7027C251.07 59.1169 250.883 55.6074 249.721 52.1742Z"
        fill="url(#paint45_linear)"
      />
      <path
        d="M247.284 59.4602C247.209 58.6591 247.209 57.8199 247.097 57.0188C246.872 55.1877 246.272 53.4711 245.41 51.869C245.223 51.4875 244.923 51.1442 244.585 50.8772C244.173 50.5338 243.761 50.7246 243.761 51.2968C243.761 51.6782 243.761 52.0979 243.836 52.4793C244.248 55.3785 244.135 58.2776 243.873 61.1768C243.798 61.9397 243.723 62.7026 243.723 63.4656C243.723 63.847 243.798 64.2285 243.948 64.5718C244.36 65.5255 245.485 65.6781 246.197 64.9151C246.385 64.7244 246.497 64.4574 246.609 64.2285C246.759 63.8852 246.834 63.5419 246.909 63.1985"
        fill="white"
      />
      <path
        d="M238.775 77.7705C235.289 78.4571 231.841 78.152 228.58 76.7024C225.094 75.1384 222.47 72.6207 220.783 69.1875C219.396 66.3646 218.797 63.351 218.797 60.223C218.797 56.2176 219.846 52.4411 221.758 48.9316C222.02 48.4357 222.32 47.9398 222.62 47.4438C221.42 48.8171 220.446 50.343 219.584 52.0215C217.747 55.7217 216.885 59.6508 217.11 63.7706C217.335 68.0431 218.722 71.8959 221.533 75.1765C222.77 76.5879 224.232 77.7323 225.881 78.6097C225.994 78.686 226.106 78.7242 226.256 78.8004C226.293 78.8004 226.293 78.8386 226.293 78.9149C226.181 78.953 226.068 79.0293 225.956 79.1056C225.806 79.2201 225.619 79.2964 225.469 79.4489C225.094 79.8304 225.094 80.25 225.431 80.6315C225.956 81.28 226.668 81.6233 227.455 81.814C227.568 81.8522 227.643 81.8903 227.755 81.9285C228.018 81.9285 228.318 81.9285 228.58 81.9285C228.655 81.8903 228.692 81.8522 228.767 81.814C229.442 81.547 229.629 81.1274 229.33 80.4408C229.217 80.25 229.142 80.0211 229.03 79.7923C229.142 79.7923 229.217 79.7541 229.292 79.7923C232.403 80.8222 235.439 80.4789 238.401 79.2582C239.975 78.6097 241.399 77.7323 242.674 76.7024C241.399 77.122 240.125 77.5035 238.775 77.7705Z"
        fill="url(#paint46_linear)"
      />
      <path
        d="M238.176 88.1465C237.276 91.2745 235.515 91.6941 234.503 93.6015C233.453 95.5851 233.64 96.9965 233.565 98.5224"
        stroke="#5384EE"
        strokeWidth="0.5294"
        strokeMiterlimit="10"
      />
      <path
        d="M239.488 89.5578C239.188 89.4815 238.888 89.3671 238.626 89.2908C238.551 89.2145 238.438 89.1382 238.363 89.0619C237.576 88.6041 236.977 87.9556 236.602 87.1164C236.377 86.5823 236.527 86.1246 237.014 85.8576C237.201 85.7431 237.426 85.705 237.651 85.6668C237.801 85.6287 237.951 85.6287 238.064 85.5905C238.064 85.5142 238.064 85.4761 238.064 85.4379C237.951 85.3235 237.839 85.2091 237.726 85.0946C236.227 83.6069 235.027 81.9284 234.165 79.983C232.216 75.5579 232.029 70.9803 233.153 66.3264C234.24 61.8251 236.452 57.9342 239.6 54.6154C242.749 51.3348 246.497 49.046 250.958 48.016C254.481 47.2149 257.93 47.3675 261.266 48.7408C264.827 50.1904 267.563 52.6318 269.4 56.0268C270.899 58.8115 271.574 61.8251 271.687 64.9913C271.836 69.0348 270.899 72.8495 269.1 76.4735C267.039 80.6696 264.077 84.1028 260.179 86.6205C257.293 88.4897 254.182 89.7104 250.733 89.9774C247.172 90.2826 243.836 89.5959 240.837 87.4597C240.762 87.4216 240.687 87.4216 240.575 87.3834C240.612 87.6886 240.65 87.9175 240.687 88.1845C240.8 89.0237 240.463 89.4052 239.638 89.4815C239.675 89.4815 239.6 89.5196 239.488 89.5578Z"
        fill="url(#paint47_linear)"
      />
      <path
        d="M271.762 64.992C271.649 61.8258 270.975 58.8122 269.475 56.0275C267.639 52.5943 264.902 50.1911 261.341 48.7415C260.704 48.4745 260.104 48.2838 259.467 48.093C257.705 48.4745 256.019 49.1611 254.407 50.0766C251.033 51.984 248.447 54.7305 246.535 58.1256C244.886 61.0248 243.949 64.1528 243.874 67.5478C243.836 70.18 244.249 72.7358 245.373 75.1009C246.76 78.0382 248.934 80.1744 251.82 81.5477C254.557 82.8447 257.443 83.1117 260.367 82.6158C262.841 82.1962 265.09 81.2807 267.114 79.9837C267.901 78.8774 268.576 77.6949 269.213 76.436C270.975 72.8884 271.874 69.0737 271.762 64.992Z"
        fill="url(#paint48_linear)"
      />
      <path
        d="M266.776 71.934C266.964 71.0566 267.226 70.1792 267.376 69.2637C267.713 67.2419 267.638 65.2202 267.226 63.1984C267.113 62.7406 266.926 62.2829 266.664 61.8633C266.326 61.3673 265.839 61.4436 265.652 62.0158C265.502 62.4355 265.389 62.8932 265.314 63.3128C264.827 66.5553 263.74 69.607 262.503 72.6206C262.166 73.4217 261.866 74.2228 261.603 75.0239C261.491 75.4053 261.416 75.8631 261.453 76.2827C261.603 77.4653 262.728 77.9993 263.74 77.389C264.002 77.2364 264.227 77.0075 264.415 76.7786C264.677 76.4734 264.864 76.1301 265.089 75.7868"
        fill="white"
      />
      <path
        d="M251.745 88.6042C247.772 88.1465 244.211 86.6969 241.212 83.9885C238.026 81.1275 236.04 77.5798 235.327 73.3074C234.765 69.8361 235.14 66.4029 236.152 63.0841C237.464 58.8117 239.825 55.1496 242.974 52.0597C243.424 51.6019 243.911 51.1823 244.361 50.7627C242.637 51.8308 241.062 53.1278 239.638 54.6537C236.489 57.9343 234.278 61.8252 233.191 66.3266C232.066 70.9805 232.254 75.5581 234.203 79.9831C235.065 81.9286 236.264 83.6452 237.764 85.0947C237.876 85.2092 237.989 85.3236 238.101 85.4381C238.101 85.4762 238.101 85.5144 238.101 85.5907C237.951 85.6288 237.801 85.6288 237.689 85.6669C237.464 85.7051 237.239 85.7432 237.052 85.8577C236.527 86.1247 236.377 86.5825 236.639 87.1165C237.014 87.9557 237.614 88.6042 238.401 89.062C238.513 89.1383 238.588 89.2146 238.663 89.2909C238.963 89.3672 239.263 89.4816 239.526 89.5579C239.601 89.5579 239.675 89.5198 239.75 89.5198C240.575 89.4816 240.912 89.062 240.8 88.2228C240.763 87.9557 240.725 87.7269 240.688 87.4217C240.8 87.4598 240.875 87.4598 240.95 87.498C243.911 89.6342 247.285 90.3208 250.846 90.0157C252.757 89.8631 254.557 89.4053 256.243 88.7187C254.744 88.8331 253.282 88.795 251.745 88.6042Z"
        fill="url(#paint49_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="126.135"
          y1="83.8279"
          x2="126.135"
          y2="188.466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset="1" stopColor="#DCE9FC" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="254.821"
          y1="147.512"
          x2="256.085"
          y2="159.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="267.849"
          y1="158.306"
          x2="267.849"
          y2="194.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="232.445"
          y1="189.679"
          x2="264.043"
          y2="188.783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="202.554"
          y1="167.405"
          x2="289.465"
          y2="142.087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="267.807"
          y1="152.689"
          x2="267.807"
          y2="166.22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="229.609"
          y1="164.263"
          x2="264.11"
          y2="161.351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="271.582"
          y1="160.982"
          x2="289.471"
          y2="170.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="236.845"
          y1="167.411"
          x2="236.845"
          y2="202.388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="201.522"
          y1="197.328"
          x2="234.091"
          y2="196.331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="173.307"
          y1="175.418"
          x2="256.431"
          y2="148.642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="236.592"
          y1="162.39"
          x2="236.592"
          y2="174.815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="198.509"
          y1="173.018"
          x2="234.106"
          y2="169.636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="241.841"
          y1="170.118"
          x2="257.429"
          y2="177.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="179.325"
          y1="148.492"
          x2="180.804"
          y2="162.247"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="199.362"
          y1="161.284"
          x2="199.362"
          y2="213.111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="186.88"
          y1="205.614"
          x2="228.626"
          y2="204.508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="117.574"
          y1="173.44"
          x2="227.478"
          y2="143.423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="199.614"
          y1="154.136"
          x2="199.614"
          y2="171.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="184.841"
          y1="169.058"
          x2="230.375"
          y2="165.123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="186.41"
          y1="166.019"
          x2="207.781"
          y2="175.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="208.705"
          y1="156.975"
          x2="217.018"
          y2="175.158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="169.185"
          y1="160.464"
          x2="173.518"
          y2="177.113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="22.7169"
          y1="147.449"
          x2="22.7169"
          y2="193.227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="11.6589"
          y1="186.684"
          x2="47.1642"
          y2="185.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="-45.7721"
          y1="159.355"
          x2="47.9177"
          y2="137.525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="22.6328"
          y1="140.641"
          x2="22.6328"
          y2="158.057"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint27_linear"
          x1="9.63724"
          y1="154.23"
          x2="48.6177"
          y2="151.066"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint28_linear"
          x1="11.417"
          y1="151.353"
          x2="34.5345"
          y2="165.748"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear"
          x1="24.3707"
          y1="152.05"
          x2="25.4949"
          y2="163.237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear"
          x1="43.6251"
          y1="162.597"
          x2="43.6251"
          y2="200.297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint31_linear"
          x1="35.406"
          y1="194.844"
          x2="66.8113"
          y2="193.983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint32_linear"
          x1="-21.6702"
          y1="171.987"
          x2="65.6582"
          y2="147.337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset="1" stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="paint33_linear"
          x1="43.6671"
          y1="156.896"
          x2="43.6671"
          y2="170.682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint34_linear"
          x1="33.7389"
          y1="168.688"
          x2="68.2496"
          y2="165.829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint35_linear"
          x1="33.7279"
          y1="165.416"
          x2="51.8112"
          y2="175.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint36_linear"
          x1="120.48"
          y1="83.4931"
          x2="124.513"
          y2="83.4931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint37_linear"
          x1="122.47"
          y1="58.0936"
          x2="122.47"
          y2="73.6698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint38_linear"
          x1="99.1427"
          y1="107.101"
          x2="148.423"
          y2="107.101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint39_linear"
          x1="123.745"
          y1="93.374"
          x2="123.745"
          y2="111.559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint40_linear"
          x1="89.8848"
          y1="132.225"
          x2="157.71"
          y2="132.225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint41_linear"
          x1="123.745"
          y1="116.096"
          x2="123.745"
          y2="135.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint42_linear"
          x1="84.8416"
          y1="164.274"
          x2="166.018"
          y2="164.274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint43_linear"
          x1="125.367"
          y1="138.5"
          x2="125.367"
          y2="169.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset="1" stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="paint44_linear"
          x1="179.755"
          y1="75.9902"
          x2="257.678"
          y2="71.0785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint45_linear"
          x1="198.284"
          y1="67.4272"
          x2="255.904"
          y2="63.8721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint46_linear"
          x1="188.743"
          y1="76.9403"
          x2="247.947"
          y2="73.5891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint47_linear"
          x1="189.033"
          y1="83.8963"
          x2="279.627"
          y2="77.5206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint48_linear"
          x1="212.993"
          y1="77.8217"
          x2="277.483"
          y2="73.8742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="paint49_linear"
          x1="206.222"
          y1="84.3967"
          x2="261.194"
          y2="81.8656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset="1" stopColor="#CCE1F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CelebrateIcon;
