// @ts-nocheck
import { NotificationVariants } from '@lobox/utils';
import type { NotificationType } from '@lobox/utils';

import PostGotReaction from './components/PostGotReaction';
import PostGotComment from './components/PostGotComment';
import CommentGotReaction from './components/CommentGotReaction';
import CommentGotReply from './components/CommentGotReply';
import PostGetShared from './components/PostGetShared';
import TaggedOnPost from './components/TaggedOnPost';
import MentionedOnPost from './components/MentionedOnPost';
import AddProfilePhoto from './components/AddProfilePhoto';
import AddHeaderImage from './components/AddHeaderImage';
import AddProfileInformation from './components/AddProfileInformation';
import CompleteYourProfile from './components/CompleteYourProfile';
import TurnOnOpenForJobOpportunity from './components/TurnOnOpenForJobOpportunity';
// import RecommendationAsked from './components/RecommendationAsked';
// import RecommendationWritten from './components/RecommendationWritten';
// import RecommendationRevisionRequested from './components/RecommendationRevisionRequested';
import NewBirthday from './components/NewBirthday';
import PersonYouMayKnow from './components/PersonYouMayKnow';
import PageYouMayKnow from './components/PageYouMayKnow';
import NewPageRoleAssigned from './components/NewPageRoleAssigned';
import NewFollower from './components/NewFollower';
import FollowRequest from './components/FollowRequest';
import PasswordChanged from './components/PasswordChanged';
import NewDeviceLoggedIn from './components/NewDeviceLoggedIn';
import PublishedNewPage from './components/PublishedNewPage';
import PageRoleAccepted from './components/PageRoleAccepted';
import PageRoleDeclined from './components/PageRoleDeclined';
import FollowRequestAccepted from './components/FollowRequestAccepted';
import PersonFromYourCompany from './components/PersonFromYourCompany';
import PersonFromYourSchool from './components/PersonFromYourSchool';
import YouMentionedOnComment from './components/YouMentionedOnComment';
import SavedButNotAppliedJobFounded from './components/SavedButNotAppliedJobFounded';
import ReminderAlarmed from './components/ReminderAlarmed';
import JobCandidateRecommendationUpdated from './components/JobCandidateRecommendationUpdated';
import TopJobSuggestionFounded from './components/TopJobSuggestionFounded';
import JobApplicationCounterChanged from './components/JobApplicationCounterChanged';
import TaskUpdated from './components/TaskUpdated';
import TaskRemoved from './components/TaskRemoved';
import AttendeeDeclined from './components/AttendeeDeclined';
import AttendeeAccepted from './components/AttendeeAccepted';
import MeetingRemoved from './components/MeetingRemoved';
import MeetingUpdated from './components/MeetingUpdated';
import MeetingCreated from './components/MeetingCreated';
import MeetingAlarmed from './components/MeetingAlarmed';
import JobClosed from './components/JobClosed';
import JobAlertMatched from './components/JobAlertMatched';

// const PostGotReaction = dynamic(() => import('./components/PostGotReaction'));

const cmp: {
  [key in NotificationType]: ReactNode;
} = {
  [NotificationVariants.YOUR_POST_GOT_REACTION]: PostGotReaction,
  [NotificationVariants.YOUR_POST_GOT_COMMENT]: PostGotComment,
  [NotificationVariants.YOUR_COMMENT_GOT_REACTION]: CommentGotReaction,
  [NotificationVariants.YOUR_COMMENT_GOT_REPLY]: CommentGotReply,
  [NotificationVariants.YOU_MENTIONED_ON_COMMENT]: YouMentionedOnComment,
  [NotificationVariants.YOUR_POST_GET_SHARED]: PostGetShared,
  [NotificationVariants.YOU_TAGGED_ON_POST]: TaggedOnPost,
  [NotificationVariants.YOU_MENTIONED_ON_POST]: MentionedOnPost,
  [NotificationVariants.ADD_PROFILE_PHOTO]: AddProfilePhoto,
  [NotificationVariants.ADD_HEADER_IMAGE]: AddHeaderImage,
  [NotificationVariants.ADD_PROFILE_INFORMATION]: AddProfileInformation,
  [NotificationVariants.COMPLETE_YOUR_PROFILE]: CompleteYourProfile,
  [NotificationVariants.TURN_ON_OPEN_FOR_OPPORTUNITIES]:
    TurnOnOpenForJobOpportunity,
  // [NotificationVariants.RECOMMENDATION_ASKED]: RecommendationAsked,
  // [NotificationVariants.RECOMMENDATION_WRITTEN]: RecommendationWritten,
  // [NotificationVariants.RECOMMENDATION_REVISION_REQUESTED]: RecommendationRevisionRequested,
  [NotificationVariants.NEW_BIRTHDAY]: NewBirthday,
  [NotificationVariants.PERSON_YOU_MAY_KNOW]: PersonYouMayKnow,
  [NotificationVariants.PAGE_YOU_MAY_KNOW]: PageYouMayKnow,
  [NotificationVariants.NEW_PERSON_FROM_YOUR_SCHOOL]: PersonFromYourSchool,
  [NotificationVariants.NEW_PERSON_FROM_YOUR_COMPANY]: PersonFromYourCompany,
  [NotificationVariants.NEW_PAGE_ROLE_ASSIGNED]: NewPageRoleAssigned,
  [NotificationVariants.NEW_FOLLOWER]: NewFollower,
  [NotificationVariants.NEW_FOLLOW_REQUEST]: FollowRequest,
  [NotificationVariants.PASSWORD_CHANGED]: PasswordChanged,
  [NotificationVariants.NEW_DEVICE_LOGGED_IN]: NewDeviceLoggedIn,
  [NotificationVariants.PUBLISHED_A_NEW_PAGE]: PublishedNewPage,
  [NotificationVariants.PAGE_ROLE_ACCEPTED]: PageRoleAccepted,
  [NotificationVariants.PAGE_ROLE_DECLINED]: PageRoleDeclined,
  [NotificationVariants.YOUR_FOLLOW_REQUEST_ACCEPTED]: FollowRequestAccepted,
  [NotificationVariants.SAVED_BUT_NOT_APPLIED_JOB_FOUNDED]:
    SavedButNotAppliedJobFounded,
  [NotificationVariants.REMINDER_ALARMED]: ReminderAlarmed,
  [NotificationVariants.JOB_CANDIDATE_RECOMMENDATION_UPDATED]:
    JobCandidateRecommendationUpdated,
  [NotificationVariants.TOP_JOB_SUGGESTION_FOUNDED]: TopJobSuggestionFounded,
  [NotificationVariants.JOB_APPLICANT_COUNT_CHANGED]:
    JobApplicationCounterChanged,
  [NotificationVariants.TASK_UPDATED]: TaskUpdated,
  [NotificationVariants.TASK_REMOVED]: TaskRemoved,
  [NotificationVariants.ATTENDEE_DECLINED]: AttendeeDeclined,
  [NotificationVariants.ATTENDEE_ACCEPTED]: AttendeeAccepted,
  [NotificationVariants.MEETING_REMOVED]: MeetingRemoved,
  [NotificationVariants.MEETING_UPDATED]: MeetingUpdated,
  [NotificationVariants.MEETING_CREATED]: MeetingCreated,
  [NotificationVariants.MEETING_ALARMED]: MeetingAlarmed,
  [NotificationVariants.JOB_CLOSED]: JobClosed,
  [NotificationVariants.JOB_ALERT_MATCHED]: JobAlertMatched,
};

export default cmp;
