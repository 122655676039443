import {
  useReactMutation,
  seenNotification,
  seenAllNotification,
} from '@lobox/utils';
import type { UseMutationResult } from '@tanstack/react-query';

interface ISeenNotificationProps {
  seenAll?: boolean;
}

const useSeenNotification = ({
  seenAll,
}: ISeenNotificationProps = {}): UseMutationResult =>
  useReactMutation({
    apiFunc: seenAll ? seenAllNotification : seenNotification,
  });

export default useSeenNotification;
