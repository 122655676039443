import React from 'react';

import { ToggleNotificationList, useTranslation } from '@lobox/utils';

import { useSchedulesCalendar } from 'shared/hooks/useSchedulesCalendar';
import { getEventdataFromNotificationData } from '@shared/utils/getEventdataFromNotificationData';
import type { INotificationProps } from '../Notification.layout';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';

const MeetingRemoved: React.FC<INotificationProps> = (props) => {
  const { data, onSeen, menuActions } = props;

  const { t } = useTranslation();
  const { handleEventClick } = useSchedulesCalendar();

  const handleClicked = () => {
    if (!data.seen) onSeen?.();
    handleEventClick(undefined, getEventdataFromNotificationData(data) as any);
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClicked}
      icon={<Icon iconName="trash" />}
      description={
        <>
          <Text isBold value={data?.userTitle} />
          <Text value=" " />
          <Text value={t('removed')} />
          <Text value=" " />
          <Text isBold value={data?.meetingTitle} />
          <Text value=" " />
          <Text value={t('meeting_s')} />
          <Text value="." />
        </>
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default MeetingRemoved;
