import { schedulesEventValues } from '@lobox/utils';

type Rtype = {
  type: typeof schedulesEventValues[keyof typeof schedulesEventValues];
  id: string;
  isFromNotification: boolean;
} | null;

export function getEventdataFromNotificationData(notification: any): Rtype {
  const type = getType(notification?.type);
  const idPropertyName = getidPropertyName(notification?.type);
  if (!type || !idPropertyName) return null;
  return {
    type,
    id: notification?.[idPropertyName],
    isFromNotification: true,
  };
}

function getidPropertyName(
  type: string
): 'meetingId' | 'taskId' | 'reminderId' {
  if (getType(type) === schedulesEventValues.MEETING) return 'meetingId';
  if (getType(type) === schedulesEventValues.REMINDER) return 'reminderId';
  if (getType(type) === schedulesEventValues.TASK) return 'taskId';
  return undefined;
}

function getType(type: string): Rtype['type'] {
  return [
    schedulesEventValues.MEETING,
    schedulesEventValues.TASK,
    schedulesEventValues.REMINDER,
  ]?.find((item) => type?.includes(item));
}
