import React from 'react';
import { routeNames, useHistory } from '@lobox/utils';
import { ToggleNotificationList, useTranslation } from '@lobox/utils';
import type { INotificationProps } from '../Notification.layout';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';

const SavedButNotAppliedJobFounded: React.FC<INotificationProps> = (props) => {
  const { data, onSeen, menuActions } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const handleClicked = () => {
    if (!data.seen) onSeen?.();
  };

  const handleViewJobs = () => {
    history.push({ pathname: routeNames.savedJobs });
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      to={routeNames.savedJobs}
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClicked}
      icon={<Icon iconName="lightbulb-on" />}
      description={
        <>
          <Text value={t('saved_not_applied')} />
          <Text value="." />
        </>
      }
      primaryAction={{
        closeModal: true,
        label: t('view_jobs'),
        onClick: handleViewJobs,
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default SavedButNotAppliedJobFounded;
