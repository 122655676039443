import React from 'react';
import { useHistory } from '@lobox/utils';
import { ToggleNotificationList, useTranslation } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const AddProfileInformation: React.FC<INotificationProps> = ({
  data,
  onSeen,
  onDelete,
  menuActions,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { getAppObjectPropValue } = useGetAppObject();

  const id = getAppObjectPropValue({ userKey: 'id', pageKey: 'id' });
  const username = getAppObjectPropValue({
    userKey: 'username',
    pageKey: 'username',
  });

  const handleClicked = () => {
    onSeen?.();
    history.push(`/${username}`);
  };
  const hasToggleNotification = ToggleNotificationList.includes(data.type);
  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={onSeen}
      objectId={id}
      icon={<Icon iconName="lightbulb-on" />}
      description={
        <>
          <Text isBold value={t('tip_cap')} />
          <Text isBold value=": " />

          <br />

          <Text value={t('update_profile_info')} />
        </>
      }
      primaryAction={{
        label: t('update_profile'),
        closeModal:true,
        onClick: handleClicked,
      }}
      secondaryAction={{
        label: t('decline'),
        onClick: () => {
          onDelete();
        },
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default AddProfileInformation;
