import React from 'react';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import {
  APP_ENTITIES,
  preventClickHandler,
  routeNames,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import { useHistory } from '@lobox/utils';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import Layout, { Avatar, Text, UserTitle } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';
import useHasPermission from '../../../../hooks/useHasPermission';
import useGlobalSearchUtilities from '@shared/hooks/useGlobalSearchUtilities';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';
import Flex from '@shared/uikit/Flex';
import NoEvent from '@shared/uikit/NoEvent';

const CommentGotReply: React.FC<INotificationProps> = ({
  data,
  onSeen,
  menuActions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasToggleNotification = ToggleNotificationList.includes(data.type);
  const canSeeHomeScreen = useHasPermission([SCOPES.canSeeHomeScreen]);

  const handleMentionClicked = (
    username: string,
    event: React.MouseEvent<any>
  ) => {
    onSeen();
    preventClickHandler(event);
    history.push(`/${username}`);
  };

  const { searchForText } = useGlobalSearchUtilities();

  const handleHashtagClicked = (
    hashtag: string,
    event: React.MouseEvent<any>
  ) => {
    if (canSeeHomeScreen) {
      onSeen();
      preventClickHandler(event);
      searchForText(hashtag, 'hashtags');
    }
  };

  const navigateSearchPage = useNavigateSearchPage();

  const handleClick = (...args) => {
    onSeen(...args);
    navigateSearchPage({
      pathname: routeNames.searchPosts,
      currentEntityId: data.postId,
    });
  };

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClick}
      icon={
        <Avatar
          objectId={data.userId}
          isCompany={data?.userType === APP_ENTITIES.page}
          src={data?.croppedImageUrl}
        />
      }
      description={
        <>
          <UserTitle title={data?.userTitle} objectId={data.userId} />
          <Text value={` ${t('rep_y_comm')}`} />
          <NoEvent>
            <RichTextView
              // // onMentionClick={handleMentionClicked}
              // onHashtagClick={canSeeHomeScreen ? handleHashtagClicked : undefined}
              html={data.commentText}
              typographyProps={{
                size: 13,
                color: 'primaryText',
              }}
              showMore
              row={3}
              moreButtonVisible={false}
            />
          </NoEvent>
        </>
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default CommentGotReply;
