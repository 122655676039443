import { useReactMutation, declineMemberShip } from '@lobox/utils';
import type { UseMutationResult } from '@tanstack/react-query';

const useDeclineMemberShip = (): UseMutationResult => {
  return useReactMutation({
    apiFunc: declineMemberShip,
  });
};

export default useDeclineMemberShip;
