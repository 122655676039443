import { useReactMutation, acceptMemberShip } from '@lobox/utils';
import type { UseMutationResult } from '@tanstack/react-query';

const useAcceptMemberShip = (): UseMutationResult => {
  return useReactMutation({
    apiFunc: acceptMemberShip,
  });
};

export default useAcceptMemberShip;
