import classes from './Notification.skeleton.module.scss';

import React from 'react';
import Box from '@lobox/uikit/Layout/Box';
import Flex from '@lobox/uikit/Flex';
import Skeleton from '@lobox/uikit/Skeleton';

const NotificationSkeleton: React.FC = () => {
  return (
    <Box className={classes.root}>
      <Skeleton className={classes.avatar} />
      <Flex className={classes.info}>
        <Skeleton className={classes.info1} />
        <Skeleton className={classes.info2} />
        <Skeleton className={classes.info3} />
      </Flex>
    </Box>
  );
};

export default NotificationSkeleton;
