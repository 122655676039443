import React from 'react';
import {
  setNotificationsOpened as setNotifsOpened,
  useReactMutation,
  QueryKeys,
} from '@lobox/utils';
import { useQueryClient } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';

type UseSetNotificationsOpenedResult = Omit<UseMutationResult, 'mutate'> & {
  setNotificationsOpened: () => void;
};

const useSetNotificationsOpened = (): UseSetNotificationsOpenedResult => {
  const queryClient = useQueryClient();

  const updateUnOppendedCount = React.useCallback(() => {
    const notifCounts: any =
      queryClient.getQueryData([QueryKeys.unSeenNotificationCounts]) || {};
    queryClient.setQueryData([QueryKeys.unSeenNotificationCounts], {
      ...notifCounts,
      unOpenedCount: 0,
    });
  }, [queryClient]);

  const { mutate, ...rest } = useReactMutation({
    apiFunc: setNotifsOpened,
  });

  const setNotificationsOpened = React.useCallback(() => {
    mutate(null, {
      onSuccess: () => {
        updateUnOppendedCount();
      },
    });
  }, [mutate, updateUnOppendedCount]);

  return {
    setNotificationsOpened,
    ...rest,
  };
};

export default useSetNotificationsOpened;
