import React from 'react';

import {
  ToggleNotificationList,
  translateReplacer,
  useTranslation,
} from '@lobox/utils';
import type { INotificationProps } from '../Notification.layout';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';

const JobAlertMatched: React.FC<INotificationProps> = (props) => {
  const { data, onSeen, menuActions } = props;
  const { t } = useTranslation();
  const navigateSearchPage = useNavigateSearchPage();

  const handleClicked = () => {
    navigateSearchPage({
      placeId: data.locationId,
      placeTitle: data.locationTitle,
      query: data.jobTitle,
    });
    if (!data.seen) onSeen?.();
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClicked}
      icon={<Icon iconName="lightbulb-on" />}
      description={
        <>
          <Text isBold value={data?.jobTitle} />
          <Text value=" " />
          <Text value={t('jobs_f_in')} />
          <Text value=" " />
          <Text isBold value={data?.locationTitle} />
          <Text value=" " />
          <Text value={t('via_y_j_alert')} />
        </>
      }
      primaryAction={{
        onClick: handleClicked,
        label: translateReplacer(t('view_count_jobs'), data?.count),
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default JobAlertMatched;
