'use client';

import classes from './index.module.scss';

import React, { useState } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import useMedia from '@lobox/uikit/utils/useMedia';
import ViewPortList from '@lobox/uikit/ViewPortList';
import { MAIN_CENTER_WRAPPER_ID, useWindowDimensions } from '@lobox/utils';
import NotificationItem, {
  NotificationEmptyList,
  NotificationSkeleton,
} from '@shared/components/Organism/Notification';
import useGetNotifications from '@shared/hooks/api-hook/useGetNotifications';
import useSetNotificationOpened from '@shared/hooks/useSetNotificationOpened';
import EventModalsProvider from '@shared/contexts/eventModals.provider';
import type { Filter } from './partials/components/HeaderActions';
import NotificationHeaderActions from './partials/components/HeaderActions';
import isServer from '@lobox/utils/utils/isServer';

interface NotificationListProps {
  isModalView?: boolean;
}

const NotificationList: React.FC<NotificationListProps> = ({ isModalView }) => {
  const { setNotificationsOpened } = useSetNotificationOpened();
  const { isMoreThanTablet } = useMedia();
  let viewPortHeight: string | number = '100%';
  const { height } = useWindowDimensions();

  if (height > 0) {
    viewPortHeight = isMoreThanTablet ? height - 76 : height - 117;
  }
  const [selectedFilter, setFilter] = useState<Filter>('all');

  const onlyUnseen = selectedFilter === 'unseen';

  const {
    data = [],
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetNotifications({
    onlyUnseen,
  });

  const list = onlyUnseen ? data.filter(({ seen }) => !seen) : data;

  const handleFilterClicked = (f: Filter) => {
    setFilter(f);
  };

  React.useEffect(() => {
    setNotificationsOpened();
  }, [setNotificationsOpened]);

  return (
    <EventModalsProvider>
      <NotificationHeaderActions
        className={isModalView && classes.headerContent}
        selectedFilter={selectedFilter}
        onFilterClick={handleFilterClicked}
        markAllReadVisibility={!!list?.length}
      />

      <ViewPortList
        endReached={fetchNextPage}
        totalCount={data.length}
        style={{ height: viewPortHeight }}
        {...(isModalView || isServer()
          ? {}
          : {
              customScrollParent: document.getElementById(
                MAIN_CENTER_WRAPPER_ID
              ),
              useRelativeScroller: true,
            })}
        components={{
          Footer: () =>
            isLoading || isFetchingNextPage ? (
              <NotificationSkeleton className={classes.spinner} />
            ) : null,
          EmptyPlaceholder: () =>
            isLoading ? (
              <>
                <NotificationSkeleton />
                <NotificationSkeleton />
              </>
            ) : (
              <NotificationEmptyList filter={selectedFilter} />
            ),
        }}
        itemContent={(index) => (
          <NotificationItem
            key={list[index].id}
            data={list[index]}
            className={cnj(
              classes.notifItem,
              isModalView && classes.notifListItemClassName
            )}
          />
        )}
      />
    </EventModalsProvider>
  );
};

export default NotificationList;
