import React from 'react';
import { ToggleNotificationList, useTranslation } from '@lobox/utils';
import { useSchedulesCalendar } from 'shared/hooks/useSchedulesCalendar';
import { getEventdataFromNotificationData } from '@shared/utils/getEventdataFromNotificationData';
import type { INotificationProps } from '../Notification.layout';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';

const ReminderAlarmed: React.FC<INotificationProps> = (props) => {
  const { data, onSeen, onDelete, menuActions } = props;
  const { t } = useTranslation();
  const { handleEventClick } = useSchedulesCalendar();

  const handleClicked = () => {
    if (!data.seen) onSeen?.();
    handleEventClick(undefined, getEventdataFromNotificationData(data) as any);
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClicked}
      icon={<Icon iconName="exclamation-circle" />}
      description={
        <>
          <Text value={t('reminder')} />
          <Text value=":" />

          <br />

          <Text isBold value={data?.reminderTitle} />
        </>
      }
      primaryAction={{
        closeModal:true,
        label: t('reminder_details'),
        onClick: handleClicked,
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default ReminderAlarmed;
