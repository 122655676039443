import {
  depotApi,
  encoder,
  routeNames,
  useGlobalState,
  useHistory,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';
import { useMemo } from 'react';
import { useLocation } from '@lobox/utils';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import urls from '@shared/constants/urls';
import {
  searchFilterQueryParams,
  searchGroupTypes,
} from '@shared/constants/search';
import useMedia from '@lobox/uikit/utils/useMedia';
import type { SearchResultItem } from '@shared/components/Organism/types';
import useSearchFilters from '@shared/hooks/useSearchFilters';
import { moveArray } from '@shared/utils/moveArray';
import { useObjectClicks } from './useObjectClicks';
import { mutableStore } from '@shared/constants/mutableStore';

type ItemType = any;

const modules = [
  'posts',
  'people',
  'pages',
  'jobs',
  'hashtags',
  'locations',
  'all',
] as const;
type Module = (typeof modules)[number];

type LocationItem = {
  category: string;
  id: string;
  label: string;
  lang: string;
  lat: string;
  lon: string;
  title: string;
  value: string;
};

type RType = {
  currentModule: Module;

  onSelectHandler: {
    recents: Function;
    suggestions: Function;
    items: Function;
    location: Function;
    all: Function;
  };
  getQueryKey: (
    module: Module,
    apiType: ApiType,
    ...values: string[]
  ) => string[];

  searchForText: (text: string, module?: Module) => void;
  searchInCarouselItems: {
    label: string;
    src: string;
    alt: string;
    type: Module;
  }[];
  moduleSpecificSearchResultPage: { isActive: boolean; module: Module };
  removeRecentSearch: (
    key: string,
    { module, onSuccess }: { module?: Module; onSuccess?: Function }
  ) => void;
  removeAllRecentSearches: (x: {
    moduleType?: 'GLOBAL' | 'JOBS' | 'PEOPLE' | 'POSTS' | 'PAGES' | 'LOCATIONS';
    onSuccess?: Function;
  }) => void;
  currentGeneralModule: CurrentGeneralModule;
  isSearchPageOnMobile: boolean;
  isSearchPage: boolean;
  isSearchAllPage: boolean;
  addToHistory: (item: any, mod: Module) => void;
};
export type ApiType =
  | 'recents'
  | 'suggestions'
  | 'items'
  | 'location'
  | 'all'
  | 'locationSuggestions'
  | 'locationRecents';

export type CurrentGeneralModule = 'jobs' | 'all' | 'pages' | 'people';

export type TextArg = { params: { text: string } };

type ItemTypeWithGlobal = ItemType | 'GLOBAL';

const useGlobalSearchUtilities = (): RType => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const { t } = useTranslation();
  const { isTabletAndLess } = useMedia();
  const isSearchAllPage = pathname.startsWith(routeNames.searchAll);
  const isSearchPage = pathname.startsWith(routeNames.search);
  const isOpenProfilePanel = useGlobalState('isOpenProfilePanel');
  const { handleHashtagClick } = useObjectClicks();
  const navigateSearchPage = useNavigateSearchPage();

  const isSearchPageOnMobile =
    isTabletAndLess && isSearchPage && !isOpenProfilePanel;

  const { mutate: addToHistoryMutation } = useReactMutation({
    apiFunc: depotApi.addSearchHistory,
  });
  const queryClient = useQueryClient();
  const { mutate: removeRecentSearchMutation } = useReactMutation({
    apiFunc: depotApi.deleteSearchHistory,
  });
  const { mutate: removeAllRecentSearchesMutation } = useReactMutation({
    apiFunc: depotApi.deleteAllSearchHistory,
  });
  const { setFilters, searchFilters, refetchWithCurrentEntityId } =
    useSearchFilters();

  const currentModule: Module =
    modules?.find((item) => pathname.includes(item)) || 'all';

  const currentGeneralModule: CurrentGeneralModule = useMemo(
    () =>
      [
        { path: routeNames.jobs, value: 'jobs' },
        { path: routeNames.pages, value: 'pages' },
        { path: routeNames.people, value: 'people' },
      ]?.reduce((acc, cur) => (pathname?.includes(cur.path) ? cur : acc), {
        path: '',
        value: 'all',
      }),
    [pathname]
  )?.value as CurrentGeneralModule;

  const moduleSpecificSearchResultPage: { isActive: boolean; module: Module } =
    useMemo(() => {
      const isModuleSpecificSearchActive = [
        routeNames.searchJobs,
        routeNames.searchPages,
        routeNames.searchPeople,
        routeNames.searchPosts,
        routeNames.searchHashtags,
      ].some((item) => pathname.includes(item));
      return {
        isActive: isModuleSpecificSearchActive,
        module: (isModuleSpecificSearchActive && currentModule) || undefined,
      };
    }, [pathname, currentModule]);

  const cacheKey = getQueryKey('all', 'recents', 'suggestions');
  const search = (text: string, path: string) => {
    if (currentModule === 'all') {
      return history.push({
        pathname: path,
        search: new URLSearchParams({
          [searchFilterQueryParams.query]: text,
        }).toString(),
      });
    }

    const isSameModule = path === pathname;

    const params = new URLSearchParams(
      JSON.parse(
        JSON.stringify({
          ...(isSameModule ? searchFilters : {}),
          [searchFilterQueryParams.searchGroupType]: searchGroupTypes.ALL,
          [searchFilterQueryParams.query]: encoder(text),
          [searchFilterQueryParams.currentEntityId]: undefined,
        })
      )
    );
    // TODO: it won't work, we should find a solution maybe pushState
    history.push({
      pathname: path,
      search: params.toString(),
    });
    queryClient.invalidateQueries(mutableStore.searchQueryKey);
  };

  const searchForText: RType['searchForText'] = (
    text: string,
    searchInmodule: Module = moduleSpecificSearchResultPage?.isActive
      ? moduleSpecificSearchResultPage?.module
      : 'all'
  ) => {
    const isJobs = searchInmodule === 'jobs';
    text = text?.trim();
    const item = {
      title: text,
      value: text,
      imgUrl: '',
      label: text,
      type: 'TEXT',
      ...(isJobs && {
        locationName: searchFilters?.placeTitle,
        locationId: searchFilters?.placeId,
      }),
    };
    addToHistory(item, searchInmodule);

    switch (searchInmodule) {
      case 'jobs': {
        search(text, routeNames.searchJobs);
        break;
      }
      case 'posts': {
        search(text, routeNames.searchPosts);
        break;
      }
      case 'pages': {
        search(text, routeNames.searchPages);
        break;
      }
      case 'people': {
        search(text, routeNames.searchPeople);
        break;
      }
      case 'hashtags': {
        search(text, routeNames.searchPosts);
        break;
      }
      default: {
        search(text, routeNames.searchAll);
      }
    }
  };
  const onSpecificSelectHandler = (item: SearchResultItem) => {
    addToHistory(item);
    switch (item?.type) {
      case 'PAGE':
      case 'PERSON': {
        history.push(routeNames.profile.replace(':username', item?.username));
        break;
      }
      case 'JOB': {
        navigateSearchPage({
          pathname: routeNames.searchJobs,
          query: item?.label,
          placeTitle: item?.locationName,
          placeId: item?.locationId,
        });
        break;
      }
      default: {
        alert('onSpecificSelectHandler default case, please report this issue');
      }
    }
    refetchWithCurrentEntityId();
  };
  const onNonSpecificSelectHandler = (item: SearchResultItem) => {
    switch (item?.type) {
      case 'JOB':
        {
          const isClickingAJobStringInsideJobsPage =
            moduleSpecificSearchResultPage?.isActive &&
            moduleSpecificSearchResultPage?.module === 'jobs';
          if (isClickingAJobStringInsideJobsPage) {
            searchForText(item?.label, 'jobs');
          } else {
            searchForText(item?.label, 'all');
          }
        }
        break;
      case 'HASHTAG': {
        handleHashtagClick(item?.label);
        break;
      }
      // eslint-disable-line no-fallthrough
      default: {
        searchForText(item?.label, 'all');
      }
    }
  };

  const onSelectHandler: RType['onSelectHandler'] = {
    recents: (item: SearchResultItem) => {
      const { isSpecific } = item;
      if (isSpecific) {
        onSpecificSelectHandler(item);
      } else {
        const isJobs = moduleSpecificSearchResultPage.module === 'jobs';
        searchForText(item?.label, isJobs ? 'jobs' : 'all');
      }
    },
    suggestions: (item: SearchResultItem) => {
      searchForText(item?.label);
    },
    items: (item: SearchResultItem) => {
      const { isSpecific } = item;
      if (isSpecific) {
        onSpecificSelectHandler(item);
      } else {
        onNonSpecificSelectHandler(item);
      }
    },
    location: (item: LocationItem) => {
      let loc = { ...item, name: item?.label };
      if (!item?.dontSearchSet) {
        loc = findLocation(item);
      }
      addToHistory(loc, 'locations');
      setFilters({
        ...searchFilters,
        [searchFilterQueryParams.currentEntityId]: undefined,
        [searchFilterQueryParams.placeId]: loc?.value,
        [searchFilterQueryParams.placeTitle]: loc?.label,
        [searchFilterQueryParams.isPlaceTitleFiltered]:
          loc?.label && !loc?.isDefault ? 'true' : undefined,
      });
    },
    all: (item: SearchResultItem) => {
      searchForText(item?.label, 'all');
    },
  };
  const searchInCarouselItems = modules
    ?.filter((item) => item !== 'all' && item !== 'hashtags')
    ?.map((item) => ({
      label: t(item),
      src: `${urls.storageBaseUrl}/assets/images/search-category/search-category-${item}.png`,
      alt: t(item),
      type: item,
    }))
    ?.sort((x, y) =>
      x.type === currentGeneralModule
        ? -1
        : y.type === currentGeneralModule
          ? 1
          : 0
    );

  function getQueryKey(
    module: Module,
    apiType: ApiType,
    ...values: string[]
  ): any[] {
    return [
      'SEARCH',
      module,
      moduleSpecificSearchResultPage?.isActive?.toString(),
      moduleSpecificSearchResultPage?.module,
      apiType,
      ...values,
    ];
  }

  const findLocation = (loc: any) => {
    if ((loc?.label && loc?.value) || isEmpty(loc)) {
      return loc;
    }
    const locations =
      (queryClient.getQueryData(['LOCATION', loc?.label]) as any[]) || [];
    return locations?.[0];
  };

  function addToHistory(item: any, searchInmodule?: Module): void {
    const isJobs = searchInmodule === 'jobs' || item?.type === 'JOB';
    const isLocation = searchInmodule === 'locations';
    const moduleType = isJobs ? 'JOBS' : isLocation ? 'LOCATIONS' : 'GLOBAL';
    const { isValid, order } = updateCacheForAddToHistory(item, searchInmodule);

    if (!isValid && !order) return;

    const _item: Required<{
      moduleType: ItemTypeWithGlobal;
      entityType: ItemType;
    }> = {
      ...item,
      moduleType,
      entityType: isLocation ? 'LOCATION' : item?.type,
      label: item?.label || item?.title,
      ...(isLocation && { locationName: item?.label, locationId: item?.value }),
    };

    addToHistoryMutation(_item, {
      onSettled: () => {
        queryClient.refetchQueries(cacheKey, { active: true });
      },
    });
  }
  function updateCacheForAddToHistory(
    item: any,
    searchInmodule?: Module
  ): {
    isValid: boolean;
    order: number | null;
  } {
    // return false if it is empty
    if (!item?.value) {
      return { isValid: false, order: null };
    }
    const historyData = (queryClient.getQueryData(cacheKey) as any) || {};
    const recents: any[] =
      historyData?.[
        searchInmodule === 'locations' ? 'recentLocations' : 'recents'
      ] || [];

    const isJobs = searchInmodule === 'jobs';
    const recentIds = recents
      ?.map((item) => item?.id)
      ?.slice(0, 2)
      ?.filter(Boolean);

    const recentLabels = recents
      ?.map((item) => makeUniqueLabel(item, isJobs))
      ?.slice(0, 2)
      ?.filter(Boolean);

    if (
      recentIds?.includes(item?.id) ||
      recentLabels?.includes(makeUniqueLabel(item, isJobs))
    ) {
      let order = recentIds?.findIndex((i) => i === item?.id);
      if (order === -1)
        order = recentLabels?.findIndex(
          (i) => i === makeUniqueLabel(item, isJobs)
        );
      if (order > -1) moveArray(recents, order, 0);
      queryClient.setQueryData(cacheKey, historyData);
      return { isValid: false, order };
    }
    recents.unshift(item);
    queryClient.setQueryData(cacheKey, historyData);
    return { isValid: true, order: null };
  }

  function removeRecentSearch(
    key: string,
    { onSuccess, module }: { onSuccess?: Function; module?: Module }
  ): void {
    const historyData = queryClient.getQueryData(cacheKey) as any;
    const moduleKey = module === 'locations' ? 'recentLocations' : 'recents';
    const recents: any[] = historyData?.[moduleKey];
    const _recents = recents?.filter((item) => item?.key !== key);
    historyData[moduleKey] = _recents;
    queryClient.setQueryData(cacheKey, historyData);
    removeRecentSearchMutation(
      { key },
      {
        onSettled: () => {
          queryClient.refetchQueries(cacheKey, { active: true });
        },
        onSuccess: onSuccess as any,
      }
    );
  }
  function removeAllRecentSearches({
    moduleType = 'GLOBAL',
    onSuccess,
  }: Parameters<RType['removeAllRecentSearches'][0]>): void {
    const historyData = queryClient.getQueryData(cacheKey) as any;
    const moduleKey =
      moduleType === 'LOCATIONS' ? 'recentLocations' : 'recents';

    historyData[moduleKey] = [];
    queryClient.setQueryData(cacheKey, historyData);

    removeAllRecentSearchesMutation(
      {
        type: moduleType,
      },
      {
        onSettled: () => {
          queryClient.refetchQueries(cacheKey, { active: true });
        },
        onSuccess: onSuccess as any,
      }
    );
  }

  return {
    currentModule,
    onSelectHandler,
    getQueryKey,
    searchForText,
    searchInCarouselItems,
    moduleSpecificSearchResultPage,
    removeRecentSearch,
    removeAllRecentSearches,
    currentGeneralModule,
    isSearchPageOnMobile,
    isSearchPage,
    isSearchAllPage,
    addToHistory,
  };
};

export default useGlobalSearchUtilities;

function makeUniqueLabel(item: any, isJobs: boolean) {
  return item?.label.concat(isJobs ? item?.locationId : '');
}
