import React from 'react';

import {
  routeNames,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import type { INotificationProps } from '../Notification.layout';
import Layout, { Avatar, Text } from '../Notification.layout';

const JobClosed: React.FC<INotificationProps> = (props) => {
  const { data, onSeen, menuActions } = props;
  const { t } = useTranslation();

  const handleClicked = () => {
    if (!data.seen) onSeen?.();
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      to={routeNames.savedJobs}
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClicked}
      icon={<Avatar objectId="" isCompany src={data?.croppedImageUrl} />}
      description={
        <>
          <Text isBold value={data?.pageTitle} />
          <Text value=" " />
          <Text value={t('closed_the')} />
          <Text value=" " />
          <Text isBold value={data?.jobTitle} />
          <Text value=" " />
          <Text value={t('job')} />
          <Text value=" " />
          <Text value={t('in_your_saved_jobs')} />
          <Text value="." />
        </>
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default JobClosed;
