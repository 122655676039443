import React, { useState } from 'react';
import {
  APP_ENTITIES,
  useTranslation,
  ToggleNotificationList,
  translateReplacer,
} from '@lobox/utils';
import useAcceptMemberShip from '@shared/hooks/api-hook/useAcceptMemberShip';
import useDeclineMemberShip from '@shared/hooks/api-hook/useDeclineMemberShip';
import Layout, { Avatar, Alert } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';
import ParseTextStringCP from '@shared/components/molecules/TranslateReplacer';

const NewPageRoleAssigned: React.FC<INotificationProps> = ({
  data,
  onSeen,
  menuActions,
  updateNotification,
  decreaseBadgeCount,
}) => {
  const { t } = useTranslation();

  const [result, setResult] = useState<any>(
    data.isAcceptedAlready
      ? { text: `${t('you_accepted_role')}.` }
      : data.isDeclinedAlready
        ? { text: `${t('you_declined_role')}.`, variant: 'error' }
        : undefined
  );

  const { mutate: acceptMemberShip } = useAcceptMemberShip();
  const { mutate: declineMemberShip } = useDeclineMemberShip();

  const handleAcceptMemberShip = () => {
    acceptMemberShip(
      { role: data.pageRole, pageId: data.pageId },
      {
        onSuccess: () => {
          setResult({ text: `${t('you_accepted_role')}.` });
          updateNotification({ isAcceptedAlready: true, seen: true });
          decreaseBadgeCount();
        },
      }
    );
  };
  const handleDeclineMemberShip = () => {
    declineMemberShip(
      { role: data.pageRole, pageId: data.pageId },
      {
        onSuccess: () => {
          setResult({ text: `${t('you_declined_role')}.`, variant: 'error' });
          updateNotification({ isDeclinedAlready: true, seen: true });
          decreaseBadgeCount();
        },
      }
    );
  };
  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={onSeen}
      objectId={data.pageId}
      icon={
        <Avatar
          objectId={data.userId}
          isCompany={data?.userType === APP_ENTITIES.page}
          src={data?.croppedImageUrl}
        />
      }
      description={
        <ParseTextStringCP
          textString={translateReplacer(t('name_req_y_t_b_name_of_name_page'), [
            data?.userTitle,
            t(data.pageRole?.toLowerCase()),
            data?.pageTitle,
          ])}
        />
      }
      moreContent={
        result ? (
          <Alert text={result.text} variant={result.variant} />
        ) : undefined
      }
      primaryAction={
        !result && {
          label: t('accept'),
          onClick: handleAcceptMemberShip,
        }
      }
      secondaryAction={
        !result && {
          label: t('decline'),
          onClick: handleDeclineMemberShip,
        }
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default NewPageRoleAssigned;
