import React, { useState } from 'react';
import {
  APP_ENTITIES,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import Layout, { Avatar, Text, UserTitle, Alert } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const PersonFromYourCompany = ({
  data,
  onSeen,
  isLoadingDelete,
  onDelete,
  menuActions,
  updateNotification,
  decreaseBadgeCount,
}: INotificationProps): JSX.Element => {
  const { t } = useTranslation();
  const { networkModel } = data;
  const isPrivate = networkModel?.privateProfile;

  const [result, setResult] = useState<any>(
    data.isFollowedAlready
      ? {
          text: isPrivate
            ? `${t('your_follow_req_sent')}.`
            : `${t('you_started_following')}.`,
        }
      : data.isDeclinedAlready
        ? {
            text: `${t('you_declined_following')}.`,
            variant: 'error',
          }
        : undefined
  );

  const { followHandler, isLoading: isLoadingFollow } = useObjectNetwork();

  const handleFollowClicked = () => {
    const id = data.userId;
    followHandler(
      { id, isPage: false },
      {
        onSuccess: () => {
          setResult({
            text: isPrivate
              ? `${t('your_follow_req_sent')}.`
              : `${t('you_started_following')}.`,
          });
          decreaseBadgeCount();
          updateNotification({ isFollowedAlready: true, seen: true });
        },
      }
    );
  };

  const handleDelete = () => {
    onDelete({
      localDelete: false,
      onSuccess: () => {
        setResult({
          text: `${t('you_declined_following')}.`,
          variant: 'error',
        });
        updateNotification({ isDeclinedAlready: true, seen: true });
      },
    });
  };
  const hasToggleNotification = ToggleNotificationList.includes(data.type);
  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={onSeen}
      objectId={data.userId}
      icon={
        <Avatar
          objectId={data.userId}
          isCompany={data?.userType === APP_ENTITIES.page}
          src={data?.croppedImageUrl}
        />
      }
      description={
        <>
          <UserTitle title={data?.userTitle} objectId={data.userId} />

          <Text value=" " />
          <Text value={t('also_joined')} />
          <Text value=" " />

          <UserTitle title={data?.pageTitle} objectId={data.pageId} />

          <Text value=" " />
          <Text value={t('company')} />
          <Text value=". " />

          <Text value={t('do_you_want_to_follow')} />
        </>
      }
      moreContent={
        result ? (
          <Alert text={result.text} variant={result.variant} />
        ) : undefined
      }
      primaryAction={
        !result && {
          isLoading: isLoadingFollow,
          label: t('follow_cap'),
          onClick: handleFollowClicked,
        }
      }
      secondaryAction={
        !result && {
          isLoading: isLoadingDelete,
          label: t('decline'),
          onClick: handleDelete,
        }
      }
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default PersonFromYourCompany;
