import classes from './Notification.menu.module.scss';

import React from 'react';
import IconButton from '@lobox/uikit/Button/IconButton';
import ListItem from '@lobox/uikit/ListItem';
import PopperMenu from '@lobox/uikit/PopperMenu';
import { useTranslation } from '@lobox/utils';

interface INotificationMenuProps {
  seen: boolean;
  onToggleSeen: Function;
  onDelete: Function;
  onToggleNotification: Function;
  hasToggleNotification: boolean;
}

const NotificationMenu: React.FC<INotificationMenuProps> = ({
  seen,
  onToggleSeen,
  onDelete,
  onToggleNotification,
  hasToggleNotification,
}) => {
  const { t } = useTranslation();

  return (
    <PopperMenu
      placement="bottom-end"
      menuClassName={classes.notificationItemMenuContainer}
      closeOnScroll
      buttonComponent={
        <IconButton
          colorSchema="transparent1"
          type="far"
          name="ellipsis-h"
          size="md"
          className={classes.moreButton}
        />
      }
    >
      <MenuItem
        iconName="envelope-open"
        label={!seen ? t('mark_as_read') : t('mark_as_unread')}
        secondaryLabel={t('mark_as_read_msg')}
        iconType="fas"
        labelSize={15}
        labelFont="bold"
        onClick={onToggleSeen}
      />
      <MenuItem
        iconName="trash"
        label={t('delete')}
        secondaryLabel={t('delete_notif_msg')}
        iconType="fas"
        labelSize={15}
        labelFont="bold"
        onClick={onDelete}
      />
      {hasToggleNotification && (
        <MenuItem
          iconName="bell-slash"
          label={t('turn_off')}
          secondaryLabel={t('turn_off_notif_msg')}
          iconType="fas"
          labelSize={15}
          labelFont="bold"
          onClick={onToggleNotification}
        />
      )}
    </PopperMenu>
  );
};

const MenuItem = ({
  iconName,
  label,
  secondaryLabel,
  onClick,
  iconType = 'fal',
  labelSize = 15,
  labelFont,
}: any) => {
  return (
    <ListItem
      labelsContainerClassName={classes.labelContainerClass}
      className={classes.listItemWrapper}
      secondaryLabel={secondaryLabel}
      secondaryLabelSize={12}
      secondaryLabelColor="primaryDisabledText"
      popperItem
      leftIcon={iconName}
      leftType={iconType}
      leftSize={18}
      leftColor="thirdText"
      label={label}
      labelColor="thirdText"
      labelSize={labelSize}
      labelFont={labelFont}
      hover
      hoverColor="thirdText"
      hoverBgColor="hoverPrimary"
      onClick={onClick}
    />
  );
};

export default NotificationMenu;
