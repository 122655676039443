import React from 'react';
import { useHistory } from '@lobox/utils';
import {
  routeNames,
  ToggleNotificationList,
  useTranslation,
} from '@lobox/utils';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';
import type { INotificationProps } from '../Notification.layout';

const NewDeviceLoggedIn: React.FC<INotificationProps> = ({
  data,
  onSeen,
  onDelete,
  menuActions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClicked = () => {
    onSeen?.();
    history.push(routeNames.settingsSecurity);
  };
  const hasToggleNotification = ToggleNotificationList.includes(data.type);
  const locationTitle = data?.locationTitle
    ?.split(',')
    ?.filter((item) => !item?.includes('null'))
    .join(',');

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={() => {
        onSeen();
      }}
      to={routeNames.settingsSecurity}
      icon={<Icon iconName="shield-alt" />}
      description={
        <>
          <Text isBold value={`${t('new_login')}: `} />
          &nbsp;
          <Text
            value={`${t('a_nw_dv_log_i_f')} ${locationTitle}. ${t(
              'ds_is_nt_y_rw_l_d'
            )}.`}
          />
        </>
      }
      primaryAction={{
        closeModal:true,
        label: t('review'),
        onClick: handleClicked,
      }}
      secondaryAction={{
        label: t('decline'),
        onClick: onDelete,
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default NewDeviceLoggedIn;
