import React from 'react';
import { useHistory } from '@lobox/utils';

import { ToggleNotificationList, useTranslation } from '@lobox/utils';

import type { INotificationProps } from '../Notification.layout';
import Layout, { IconSvg as Icon, Text } from '../Notification.layout';

const JobCandidateRecommendationUpdated: React.FC<INotificationProps> = (
  props
) => {
  const { data, onSeen, onDelete, menuActions } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const handleClicked = () => {
    if (!data.seen) onSeen?.();
  };

  const handleViewCandidates = () => {
    history.push('/jobs');
    onDelete?.();
  };

  const hasToggleNotification = ToggleNotificationList.includes(data.type);

  return (
    <Layout
      hasToggleNotification={hasToggleNotification}
      menuActions={menuActions}
      onClick={handleClicked}
      icon={<Icon iconName="bell-on" />}
      description={
        <>
          <Text value={t('you_have')} />
          <Text value=" " />

          <Text isBold value={t('new_candidate_recommendations')} />
          <Text value=" ." />
        </>
      }
      primaryAction={{
        closeModal:true,
        label: t('view') + ' ' + data?.count + '+ ' + t('candidates'),
        onClick: handleViewCandidates,
      }}
      date={data?.createdDate}
      seen={data?.seen}
    />
  );
};

export default JobCandidateRecommendationUpdated;
